import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
	apiKey: 'AIzaSyDrDvsZe-L2fGM-rZ3R5trDDIhlliOE0no',
	authDomain: 'pgvala-92933.firebaseapp.com',
	projectId: 'pgvala-92933',
	storageBucket: 'pgvala-92933.appspot.com',
	messagingSenderId: '576055136415',
	appId: '1:576055136415:web:5ba79152aad23ac78a12aa',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const requestPermissionForNotification = async () => {
	const permission = await Notification.requestPermission();
	if (permission === 'granted') {
		const token = await getToken(messaging, { vapidKey: 'BBy9ExQwLS_135bpUlNIG-L40AqQLBffB4L7eebsLJ9m4FYN6uhzxW3EZn-2qFUWIAzkPhnhcx6MHpTNUsQANWA' });
		// console.log('Token gen : ' + token);
		return token;
	} else if (permission === 'denied') {
		console.log('Notification permission denied');
	}
};

export const onMessageListener = () => {
	return new Promise((resolve) => {
		onMessage(messaging, (payload) => {
			resolve(payload);
		});
	});
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FetchAccomodationDetails } from '../../api/owner/ownerdata';

export const fetchOwnerRoomList = createAsyncThunk('owner/fetchOwnerRoomList', async () => {
	const data = await FetchAccomodationDetails();
	return data;
});

const ownerSlice = createSlice({
	name: 'owner',
	initialState: {
		ownerRoomList: [],
		isLoading: false,
		isError: false,
	},
	extraReducers: (builder) => {
		builder.addCase(fetchOwnerRoomList.pending, (state) => {
			state.isLoading = true;
			state.isError = false;
		})
			.addCase(fetchOwnerRoomList.fulfilled, (state, action) => {
				state.isLoading = false;
				state.ownerRoomList = action.payload;
			})
			.addCase(fetchOwnerRoomList.rejected, (state) => {
				state.isLoading = false;
				state.isError = true;
			});
	},
});

export default ownerSlice.reducer;

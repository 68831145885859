import axios from 'axios';
import { domain } from '../../utils/domain';

const headers = {
	accept: 'application/json',
};

export const SavePg = async (formData) => {
	try {
		const response = await axios.post(`${domain}/new_pg_listing_student`, formData, {
			headers,
		});
		return response.data;
	} catch (error) {
		console.error('Failed to post pg :' + error.message);
		throw error;
	}
};

import React from 'react';
import { FaCheckCircle, FaHouseUser, FaPhoneAlt } from 'react-icons/fa';
import { IoPerson } from 'react-icons/io5';

const OwnerBookingScheduleCard = ({ detail }) => {
	return (
		<div className='w-full py-8 px-3 my-3 shadow-md border border-gray-300 rounded'>
			<div className='flex flex-col gap-4'>
				<div className='bg-gray-100 text-center px-2 py-0.5 rounded-sm'>
					<span className='font-semibold'>Status - </span>
					<span>{detail.status}</span>
				</div>

				<div className='border rounded p-1 flex gap-2 flex-col'>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<FaHouseUser
							size={20}
							color='red'
						/>
						<span className='font-medium text-gray-600'> Name of Tanent - </span>
						<span className='text-sm font-semibold text-gray-900'>{detail.name_tantant}</span>
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<IoPerson
							size={20}
							color='red'
						/>

						<span className='font-medium text-gray-600'>Agent Name - </span>
						<span className='text-sm font-semibold text-gray-900'>{detail.agent_name}</span>
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<FaPhoneAlt
							size={20}
							color='red'
						/>

						<span className='font-medium text-gray-600'>Contact - </span>
						<span className='text-sm font-semibold text-gray-900'>{detail.agent_contact}</span>
					</div>
				</div>
				<div className='w-full'>
					<button
						className='bg-red-500 hover:bg-red-600 px-4 py-1 rounded-full text-white uppercase text-sm
                 flex items-center justify-center gap-2 w-full'
					>
						<span>Advance Payment</span>
						<FaCheckCircle />
					</button>
				</div>
			</div>
		</div>
	);
};

export default OwnerBookingScheduleCard;

import axios from 'axios';
import { domain } from '../../utils/domain';

const agentToken = localStorage.getItem('agentToken');

const headers = {
	accept: 'application/json',
	Authorization: 'Bearer ' + agentToken,
};

export const FetchTodayOrders = async () => {
	if (agentToken) {
		try {
			const response = await axios.get(`${domain}/today-order`, {
				headers,
			});
			return response.data;
		} catch (error) {
			console.error('Failed to fetch today orders :' + error.message);
			throw error;
		}
	}
};

export const FetchOrderDetails = async (orderno) => {
	if (agentToken) {
		try {
			const response = await axios.get(`${domain}/order_detail/?orderno=${orderno}`, {
				headers,
			});
			return response.data;
		} catch (error) {
			console.error('Failed to fetch  order details :' + error.message);
			throw error;
		}
	}
};

export const PutDealDone = async (orderno, clid) => {
	if (agentToken) {
		try {
			const response = await axios.put(`${domain}/deal_done/?orderno=${orderno}&clid=${clid}`, null, {
				headers,
			});
			return response.data;
		} catch (error) {
			console.error('Failed to put  deal dome :' + error.message);
			throw error;
		}
	}
};

export const PutFeedBackToLandlord = async (comment, clid) => {
	if (agentToken) {
		try {
			const response = await axios.put(`${domain}/agent_feedback_to_landlord/?comment=${comment}&clid=${clid}`, null, {
				headers,
			});
			return response.data;
		} catch (error) {
			console.error('Failed to put feedback :' + error.message);
			throw error;
		}
	}
};

export const FetchAgentPaymentHistory = async () => {
	if (agentToken) {
		try {
			const response = await axios.get(`${domain}/task-payment/`, {
				headers,
			});
			return response.data;
		} catch (error) {
			console.error('Failed to fetch  agent payment history :' + error.message);
			throw error;
		}
	}
};

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const StudentPrivateRoute = (props) => {
	const navigate = useNavigate();
	const { Component } = props;
	useEffect(() => {
		const token = localStorage.getItem('token');
		if (!token) {
			// Token is not available, navigate to user login page
			navigate('/userLogin');
		}
	}, [navigate]);

	return <Component />;
};

export default StudentPrivateRoute;

import React, { useEffect, useState } from 'react';
import OwnerBookingScheduleCard from '../../components/OwnerSide/OwnerBookingScheduleCard';
import Wrapper from '../../components/Wrapper';
import { GetOwnerBookingSchedules } from '../../api/owner/ownerdata';
import { PiSmileySadThin } from 'react-icons/pi';
import Loader, { SkeletonLoader2 } from '../../components/Loader/Loader';

const OwnerBookingSchedules = () => {
	const [ownerBookingDetails, setOwnerBookingDetails] = useState([]);
	const [statusFilter, setStatusFilter] = useState('all');
	const [uniqueStatusValues, setUniqueStatusValues] = useState([]);

	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const fetchData = async () => {
		setIsError(false);
		try {
			setIsLoading(true);
			const data = await GetOwnerBookingSchedules();

			setOwnerBookingDetails(data);
			// Extract unique status values
			const statusValues = Array.from(new Set(data.map((detail) => detail.status)));
			setUniqueStatusValues(['all', ...statusValues]);
			setIsLoading(false);
		} catch (error) {
			console.error(error);
			setIsError(true);
			setIsLoading(false);
		}
	};
	useEffect(() => {
		fetchData();
	}, []);

	// Filter payments based on payment status
	const filteredBookings = statusFilter === 'all' ? ownerBookingDetails : ownerBookingDetails?.filter((booking) => booking.status === statusFilter);

	return (
		<div className='my-24 mx-4'>
			<Wrapper>
				<h1 className='text-center font-semibold text-gray-800 text-xl'>Your Booking Schedules</h1>
				{isError ? (
					<div className='text-center mt-6 text-red-500'>Network Error, Please try again</div>
				) : (
					<div className='flex flex-col'>
						{isLoading ? (
							<SkeletonLoader2 />
						) : ownerBookingDetails?.length > 0 ? (
							// {/* FILTERS */}
							<>
								<div className='flex  space-x-2 my-4'>
									{uniqueStatusValues.map((status) => (
										<button
											key={status}
											className={`px-2 py-1 rounded-full text-xs ${
												statusFilter === status
													? 'bg-red-500 text-white'
													: 'bg-white text-red-500 border border-red-500'
											}`}
											onClick={() => setStatusFilter(status)}
										>
											{status}
										</button>
									))}
								</div>
								<div className='grid md:grid-cols-3 gap-8 grid-cols-1'>
									{filteredBookings?.map((detail) => (
										<OwnerBookingScheduleCard
											key={detail.entery_id}
											detail={detail}
										/>
									))}{' '}
								</div>
							</>
						) : (
							<div className={`w-full flex flex-col items-center justify-center mt-24 px-12 gap-8 ${isLoading ? 'hidden' : ''}`}>
								<PiSmileySadThin
									size={68}
									color='gray'
								/>
								<div className='flex flex-col items-center gap-1'>
									<p className='text-gray-500 font-medium text-center'> You dont have any Booking Schedules</p>{' '}
								</div>
							</div>
						)}
					</div>
				)}
			</Wrapper>
		</div>
	);
};

export default OwnerBookingSchedules;

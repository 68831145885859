import React, { useState } from 'react';
import Wrapper from '../../components/Wrapper';
import { FaSpinner } from 'react-icons/fa';
import { SavePg } from '../../api/student/newPgListing';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import moment from 'moment';

const NewPgListing = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [agreeTerms, setAgreeTerms] = useState(false);

	const [formData, setFormData] = useState({
		date: '',
		name: 'company',
		contact: 'company',
		owner_name: '',
		owner_contact1: '',
		owner_contact2: '',
		address: '',
	});
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSave = async (e) => {
		e.preventDefault();
		setFormData((prevData) => ({
			...prevData,
			date: moment(Date.now()).format('YYYY-MM-DD'),
		}));
		if (!agreeTerms) {
			// Check if terms are agreed
			toast.error('Please agree to the terms and conditions');
			return;
		}

		setIsLoading(true);
		try {
			const isContact1Valid = /^\d{10}$/.test(formData.owner_contact1);
			if (!isContact1Valid) {
				toast.error('Phone number should be of 10 digits');
				setIsLoading(false);
				return;
			}
			if (formData.owner_contact2) {
				const isContact2Valid = /^\d{10}$/.test(formData.owner_contact2);
				if (!isContact2Valid) {
					toast.error('Enter a valid alt number');
					setIsLoading(false);
					return;
				}
				// Check if contact1 and contact2 are not the same
				if (formData.owner_contact1 === formData.owner_contact2) {
					toast.error('Contact numbers should be different');
					setIsLoading(false);
					return;
				}
			}
			const data = await SavePg(formData);
			if (data) {
				toast.success(data?.status);
				setFormData({ date: '', name: '', contact: '', owner_name: '', owner_contact1: '', owner_contact2: '', address: '' });
				setAgreeTerms(false);
			}

			setIsLoading(false);
		} catch (error) {
			toast.error(error.message);
			setIsLoading(false);
		}
	};

	return (
		<div className='min-h-screen flex items-center justify-center bg-red-100 py-24'>
			<Wrapper>
				<h1 className='text-center font-semibold text-red-900 text-xl my-6'>Join PgVala and find verified tenants</h1>
				<form
					className=' max-w-md bg-white p-8 rounded-lg shadow-lg mx-3'
					onSubmit={handleSave}
				>
					<div className='mb-8'>
						<h2 className='text-lg font-semibold text-red-600 mb-4'>Enter Owner Details</h2>
						<div className='space-y-4'>
							<input
								type='text'
								name='owner_name'
								id='owner_name'
								autoComplete='given-name'
								className='w-full  py-2 px-3 text-gray-900 border border-gray-400  sm:text-base sm:leading-6 placeholder:text-gray-400 rounded-md'
								placeholder='Owner Name'
								value={formData.owner_name}
								onChange={handleChange}
								required
							/>
							<input
								type='number'
								name='owner_contact1'
								id='owner_contact1'
								autoComplete='tel'
								className='w-full  py-2 px-3 text-gray-900 border border-gray-400  sm:text-base sm:leading-6 placeholder:text-gray-400 rounded-md'
								placeholder='Owner Contact'
								value={formData.owner_contact1}
								onChange={handleChange}
								required
							/>
							<input
								type='number'
								name='owner_contact2'
								id='owner_contact2'
								autoComplete='tel'
								className='w-full  py-2 px-3 text-gray-900 border border-gray-400  sm:text-base sm:leading-6 placeholder:text-gray-400 rounded-md'
								placeholder='Owner Alt. Contact'
								value={formData.owner_contact2}
								onChange={handleChange}
								required
							/>
							<input
								type='text'
								name='address'
								id='address'
								autoComplete='tel'
								className='w-full  py-2 px-3 text-gray-900 border border-gray-400  sm:text-base sm:leading-6 placeholder:text-gray-400 rounded-md'
								placeholder='Address'
								value={formData.address}
								onChange={handleChange}
								required
							/>
						</div>
					</div>

					<div className='mb-8'>
						<label className='flex items-center'>
							<input
								type='checkbox'
								className='form-checkbox text-red-500'
								checked={agreeTerms}
								onChange={() => setAgreeTerms(!agreeTerms)}
							/>
							<span className='ml-2 text-sm text-gray-800'>
								I agree to the{' '}
								<Link
									to={'/termsAndConditions'}
									className='text-red-500'
								>
									terms and conditions
								</Link>
							</span>
						</label>
					</div>

					<div className='flex justify-end space-x-4'>
						<Link
							to='/'
							className='bg-transparent text-red-500 border border-red-500 py-2 px-4 text-sm rounded-md focus:outline-none hover:bg-red-100  duration-300 transform transition-transform  active:scale-90'
						>
							Cancel
						</Link>
						<button
							type='submit'
							className={`flex justify-center text-white bg-red-500 border-0 py-1 md:py-2 items-center gap-2 px-4 text-sm focus:outline-none hover:bg-red-600 rounded-md md:text-lg whitespace-nowrap transform transition-transform  active:scale-90 ${
								isLoading ? 'cursor-not-allowed' : ''
							}`}
							disabled={isLoading}
						>
							Submit
							{isLoading && <FaSpinner className='animate-spin' />}
						</button>
					</div>
				</form>
			</Wrapper>
		</div>
	);
};

export default NewPgListing;

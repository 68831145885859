import React, { useEffect, useState } from 'react';
import Wrapper from '../../components/Wrapper';
import StudentBookingCard from '../../components/StudentSide/StudentBookingCard';
import { FetchStudentBookings } from '../../api/student/student';
import { SkeletonLoader2 } from '../../components/Loader/Loader';
import { PiSmileySadThin } from 'react-icons/pi';
import { useParams } from 'react-router-dom';

const StudentBookings = () => {
	const [bookings, setBookings] = useState([]);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { orderno } = useParams();

	const fetchData = async () => {
		setIsError(false);
		try {
			setIsLoading(true);
			const data = await FetchStudentBookings(orderno);
			setBookings(data);
			setIsLoading(false);
		} catch (error) {
			console.error(error);
			setIsError(true);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div className='my-24 mx-4'>
			<Wrapper>
				<h1 className='text-center font-semibold text-gray-800 text-xl my-6'>Your Scheduled Bookings</h1>
				{isError ? (
					<div className='text-center mt-6 text-red-500'>Network Error, Please try again</div>
				) : (
					<div className=''>
						{isLoading ? (
							<SkeletonLoader2 />
						) : bookings?.length > 0 && !isError ? (
							<div className='grid w-full  gap-8 grid-flow-row justify-center grid-cols-1 md:grid-cols-2 mt-8'>
								{bookings?.map((booking) => (
									<StudentBookingCard
										key={booking.entery_id}
										booking={booking}
										fetchData={fetchData}
									/>
								))}
							</div>
						) : (
							<div className={`w-full flex flex-col items-center justify-center mt-24 px-12 gap-8 ${isLoading && isError ? 'hidden' : ''}`}>
								<PiSmileySadThin
									size={68}
									color='gray'
								/>
								<div className='flex flex-col items-center gap-1'>
									<p className='text-gray-500 font-medium text-center'> Something went wrong</p>{' '}
								</div>
							</div>
						)}
					</div>
				)}
			</Wrapper>
		</div>
	);
};

export default StudentBookings;

import React from 'react';

const FilterSelect = ({ id, name, value, options, onChange, placeholder }) => {
	return (
		<select
			id={id}
			name={name}
			className={`px-1 md:px-3 md:py-1.5 py-0.5 rounded-full text-xs md:text-sm border border-gray-500 text-gray-700 font-medium ${value ? 'bg-gray-200 text-black' : ''}`}
			value={value}
			onChange={(e) => onChange(e.target.value)}
		>
			<option value=''>{placeholder}</option>
			{options.map((option) => (
				<option
					key={option}
					value={option}
				>
					{option}
				</option>
			))}
		</select>
	);
};

export default FilterSelect;

import React, { useEffect, useState } from 'react';
import { FaHouseUser, FaCalendar, FaRupeeSign, FaLocationArrow, FaPhoneAlt, FaUser } from 'react-icons/fa';
import { MdAccessTime, MdPayments } from 'react-icons/md';
import { HiOutlineCash } from 'react-icons/hi';
import moment from 'moment';
import axios from 'axios';
import { cashfree } from '../../utils/cashfree/cashfree';
import toast from 'react-hot-toast';

const OwnerPaymentHistoryCard = ({ payment }) => {
	const [sessionId, setSessionId] = useState('');

	useEffect(() => {
		const fetchSessionId = async () => {
			try {
				const response = await axios.post('YOUR_SERVER_API_ENDPOINT', {
					// Include any necessary data for session ID generation
				});
				const data = response.data;
				if (data.sessionId) {
					setSessionId(data.sessionId);
				} else {
					console.error('Failed to get session ID');
					toast.error('Failed to get session ID');
				}
			} catch (error) {
				toast.error('Error fetching session ID');
				console.error('Error fetching session ID:', error.message);
			}
		};

		fetchSessionId();
	}, []);

	const handleRedirect = () => {
		if (!sessionId) {
			toast.error('Session ID not available');
			console.error('Session ID not available');
			return;
		}

		let checkoutOptions = {
			paymentSessionId: sessionId,
			returnUrl: 'http://localhost:3000',
		};

		cashfree.checkout(checkoutOptions).then(function (result) {
			if (result.error) {
				alert(result.error.message);
			}
			if (result.redirect) {
				console.log('Redirection');
				// You can add logic here to update your UI for payment confirmation
			}
		});
	};
	return (
		<div className='w-full py-4 px-1 border mb-8 shadow-md rounded'>
			<div className='flex flex-col gap-4'>
				<div className='rounded p-3 flex gap-2 flex-col'>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<FaHouseUser
							size={16}
							color='red'
						/>
						<span className='font-medium text-sm text-gray-600'> Room ID - </span>
						<span className='text-sm font-semibold text-gray-900'>{payment.roomid}</span>
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<FaHouseUser
							size={16}
							color='red'
						/>
						<span className='font-medium text-sm text-gray-600'> Status - </span>
						<span className='text-sm font-semibold text-gray-900'>{payment.status}</span>
						{payment.status === 'pending' && (
							<button
								className='bg-red-500 rounded px-2 text-white'
								onClick={handleRedirect}
							>
								Pay now
							</button>
						)}
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<FaCalendar
							size={16}
							color='red'
						/>
						<span className='font-medium text-gray-600 text-sm'>Date - </span>
						<span className='text-sm font-semibold text-gray-900'> {moment(payment.date).format('D MMM')}</span>
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<MdAccessTime
							size={16}
							color='red'
						/>
						<span className='font-medium text-gray-600 text-sm'>Visiting time - </span>
						<span className='text-sm font-semibold text-gray-900'>{moment(payment.visting_time, 'HH:mm:ss').format('h:mm A')}</span>
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<FaUser
							size={16}
							color='red'
						/>

						<span className='font-medium text-gray-600 text-sm'>Name - </span>
						<span className='text-sm font-semibold text-gray-900'>{payment.name}</span>
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<FaPhoneAlt
							size={16}
							color='red'
						/>

						<span className='font-medium text-gray-600 text-sm'>Contact - </span>
						<span className='text-sm font-semibold text-gray-900'>{payment.contact}</span>
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<FaRupeeSign
							size={16}
							color='red'
						/>

						<span className='font-medium text-gray-600 text-sm'>Aggregate Fees - </span>
						<span className='text-sm font-semibold text-gray-900'>{payment.aggFees}</span>
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<MdPayments
							size={16}
							color='red'
						/>

						<span className='font-medium text-gray-600 text-sm'>Payment Status - </span>
						<span className='text-sm font-semibold text-gray-900'>{payment.paymentStatus}</span>
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<FaLocationArrow
							size={16}
							color='red'
						/>

						<span className='font-medium text-gray-600 text-sm'>Visiting Status - </span>
						<span className='text-sm font-semibold text-gray-900'>{}</span>
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<HiOutlineCash
							size={16}
							color='red'
						/>

						<span className='font-medium text-gray-600 text-sm'>Payment Mode - </span>
						<span className='text-sm font-semibold text-gray-900'>{payment.paymentMode}</span>
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<FaCalendar
							size={16}
							color='red'
						/>

						<span className='font-medium text-gray-600 text-sm'>Payment Settlement Date - </span>
						<span className='text-sm font-semibold text-gray-900'>{payment.paymentSettlementDate}</span>
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<FaCalendar
							size={16}
							color='red'
						/>

						<span className='font-medium text-gray-600 text-sm'>Transaction ID - </span>
						<span className='text-sm font-semibold text-gray-900'>{payment.transactionID}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OwnerPaymentHistoryCard;

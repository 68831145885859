import React, { useEffect, useRef, useState } from 'react';
import { IoMdPerson } from 'react-icons/io';
import { FaUserCircle } from 'react-icons/fa';
import { FaUserAltSlash } from 'react-icons/fa';
import { RiShieldUserFill } from 'react-icons/ri';
import Wrapper from '../Wrapper';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const Header = () => {
	const [showDropdown, setShowDropdown] = useState(false);
	const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
	const [loginRole, setLoginRole] = useState('');

	const navigate = useNavigate();

	const logo = 'https://res.cloudinary.com/du5mxasbv/image/upload/v1704728853/hkvesqcut3hwcelnm2ja.png';

	const dropdownRef = useRef(null);
	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setShowDropdown(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	const handleIconClick = (event) => {
		// Stop the event propagation to prevent immediate closing of the dropdown
		event.stopPropagation();
		setShowDropdown(!showDropdown);
	};

	const handleLogin = (role) => {
		if (!showDropdown) {
			return; // Do nothing if the dropdown is hidden
		}
		setShowDropdown(false);
		if (token || ownerToken || agentToken) {
			setLoginRole(role);
			setShowConfirmationDialog(true);
		} else {
			navigate(`/${role.toLowerCase()}Login`);
		}
	};

	const handleConfirmLogin = async () => {
		handleLogout();
		setShowConfirmationDialog(false);
		navigate(`/${loginRole.toLowerCase()}Login`);
		window.location.reload();
	};

	const handleCancelLogin = () => {
		setShowConfirmationDialog(false);
	};

	const token = localStorage.getItem('token');
	const ownerToken = localStorage.getItem('ownerToken');
	const agentToken = localStorage.getItem('agentToken');

	const handleLogout = async () => {
		try {
			if (token) {
				localStorage.removeItem('token');
			}
			if (ownerToken) {
				localStorage.removeItem('ownerToken');
			}
			if (agentToken) {
				localStorage.removeItem('agentToken');
			}
			setShowDropdown(false);
			toast.success('Logout successfully');
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} catch (error) {
			console.error('Error during logout:', error);
			// You may choose to handle the error in a way that makes sense for your application
		}
	};

	return (
		<div className='h-20 w-full shadow-md fixed top-0 mb-3 bg-white z-20'>
			<Wrapper>
				<div className='flex justify-between items-center px-5 py-2 relative'>
					<Link
						className='cursor-pointer'
						to='/'
					>
						<div className='w-10'>
							<img
								src={logo}
								alt='logo'
							/>
						</div>
						<div>
							<span className='font-semibold'>Pg</span>
							<span className='font-semibold text-red-500'>Vala</span>
						</div>
					</Link>
					<div
						className='cursor-pointer hover:bg-gray-100 rounded-full p-1'
						onClick={handleIconClick}
					>
						<IoMdPerson
							color='red'
							size={24}
						/>
					</div>

					{/* DROPDOWN ITEMS */}
					{showDropdown && (
						<div
							className={`absolute right-0 top-0 mt-14 mr-3 bg-white border rounded-md overflow-hidden`}
							ref={dropdownRef}
						>
							<div className='flex flex-col my-3'>
								{!token && (
									<div
										className='flex items-center gap-2 hover:bg-gray-100 px-4 py-2 cursor-pointer'
										onClick={() => handleLogin('User')}
									>
										<FaUserCircle
											color='red'
											size={18}
										/>
										<span className='text-sm text-gray-700'>Login as user</span>
									</div>
								)}
								{!ownerToken && (
									<div
										className='flex items-center gap-2 hover:bg-gray-100 px-4 py-2 cursor-pointer'
										onClick={() => handleLogin('Owner')}
									>
										<RiShieldUserFill
											color='red'
											size={18}
										/>
										<span className='text-sm text-gray-700'>Login as owner</span>
									</div>
								)}
								{!agentToken && (
									<div
										className='flex items-center gap-2 hover:bg-gray-100 px-4 py-2 cursor-pointer'
										onClick={() => handleLogin('Agent')}
									>
										<RiShieldUserFill
											color='red'
											size={18}
										/>
										<span className='text-sm text-gray-700'>Login as agent</span>
									</div>
								)}
								{(token || ownerToken || agentToken) && (
									<div
										className='flex items-center gap-2 hover:bg-gray-100 px-4 py-2 cursor-pointer'
										onClick={handleLogout}
									>
										<FaUserAltSlash
											color='red'
											size={18}
										/>
										<span className='text-sm text-gray-700'>Logout</span>
									</div>
								)}
							</div>
						</div>
					)}
				</div>
				{/* CONFIRMATION DIALOG */}
				{showConfirmationDialog && (
					<div className='fixed inset-0 z-50 bg-black backdrop-blur-sm bg-opacity-15 flex items-center justify-center'>
						<div className='bg-white px-4 py-4 rounded-md shadow-md mx-4'>
							<p className='mb-4 font-medium text-gray-800 text-center'>Do you really want to logout and login as {loginRole.toLowerCase()}?</p>
							<div className='flex justify-end'>
								<button
									className='mr-4 text-gray-600 hover:text-red-500 border border-red-500 px-2 py-1 rounded-md text-sm'
									onClick={handleCancelLogin}
								>
									Cancel
								</button>
								<button
									className='bg-red-500  hover:bg-red-600 text-white px-4 py-1 rounded-md text-sm'
									onClick={handleConfirmLogin}
								>
									Confirm
								</button>
							</div>
						</div>
					</div>
				)}
			</Wrapper>
		</div>
	);
};

export default Header;

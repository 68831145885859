import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RegisterAccomodation, RegisterRoom } from '../../api/onboard/registerAccomodation';

export const registerAccomodation = createAsyncThunk('onboard/registerAccomodation', async (accomodationData) => {
	const data = await RegisterAccomodation(accomodationData);
	return data;
});

export const registerRoom = createAsyncThunk('onboard/registerRoom', async (roomData) => {
	const data = await RegisterRoom(roomData);

	return data;
});

const onboardSlice = createSlice({
	name: 'onboard',
	initialState: {
		isLoading: false,
		isError: false,
		accomodationResponse: null, // New state to store the response from registerAccomodation
	},
	extraReducers: (builder) => {
		builder.addCase(registerAccomodation.pending, (state) => {
			state.isLoading = true;
		})
			.addCase(registerAccomodation.fulfilled, (state, action) => {
				state.isLoading = false;
				state.accomodationResponse = action.payload;
			})
			.addCase(registerAccomodation.rejected, (state) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(registerRoom.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(registerRoom.fulfilled, (state) => {
				state.isLoading = false;
			})
			.addCase(registerRoom.rejected, (state) => {
				state.isLoading = false;
				state.isError = true;
			});
	},
});

export default onboardSlice.reducer;

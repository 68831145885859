import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaSpinner } from 'react-icons/fa6';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../../components/Wrapper';
import { registerAccomodation } from '../../redux-store/slice/onBoardSlice';

const AddAccomodation = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const [accDetail, setAccDetail] = useState({
		apartment_name: '',
		owner_name: '',
		state: '',
		city: '',
		locality: '',
		address: '',
		contact1: '',
		contact2: '',
		email: '',
		latitude: 0,
		longitude: 0,
		total_accomation: '',
		tc: '',
		tenant: '',
		qrid: 'string',
	});

	useEffect(() => {
		const location = () => {
			if ('geolocation' in navigator) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						const { latitude, longitude } = position.coords;
						setAccDetail({
							...accDetail,
							latitude: latitude,
							longitude: longitude,
						});
					},
					(error) => {
						console.error(`Error getting location: ${error.message}`);
					}
				);
			} else {
				console.error('Geolocation is not supported by your browser');
			}
		};
		location();
	}, []);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setAccDetail((prevAccDetail) => ({
			...prevAccDetail,
			[name]: value,
		}));
	};

	const handleRegister = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		//check if accid is already in localstorage
		const accomodationId = localStorage.getItem('accidLocal');
		if (accomodationId) {
			localStorage.removeItem('accidLocal');
		}
		// Check if the contact1 is a valid 10-digit mobile number
		const isContact1Valid = /^\d{10}$/.test(accDetail.contact1);
		if (!isContact1Valid) {
			toast.error('Enter a valid Phone number');
			return;
		}

		// Check if the contact2 is provided and is a valid 10-digit mobile number
		if (accDetail.contact2) {
			const isContact2Valid = /^\d{10}$/.test(accDetail.contact2);
			if (!isContact2Valid) {
				toast.error('Enter a valid alt number');
				setIsLoading(false);
				return;
			}
			// Check if contact1 and contact2 are not the same
			if (accDetail.contact1 === accDetail.contact2) {
				toast.error('Contact numbers should be different');
				setIsLoading(false);
				return;
			}
		}

		try {
			const res = await dispatch(registerAccomodation(accDetail));
			if (res) {
				localStorage.setItem('accidLocal', res.payload.accommodation_id);
			}
			toast.success('Registered Successfully');
			setIsLoading(false);
			setTimeout(() => {
				navigate('/addRoom');
				window.scrollTo(0, 0);
			}, 2000);
		} catch (error) {
			console.error('Error in registering apartment : ' + error);
			toast.error('An error occured');
			setIsLoading(false);
		}
	};

	return (
		<div className='my-24'>
			<Wrapper>
				<h1 className='text-center font-semibold text-gray-800 text-xl'>Add PG</h1>

				<form
					className='mx-4'
					onSubmit={handleRegister}
				>
					<div className='border-b border-gray-900/10 pb-12'>
						<div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
							<div className='sm:col-span-3'>
								<label
									htmlFor='apartment_name'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Apartment name
								</label>
								<div className='mt-2'>
									<input
										type='text'
										name='apartment_name'
										id='apartment_name'
										autoComplete='given-name'
										className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset px-2 ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 text-sm'
										value={accDetail.apartment_name}
										placeholder='Enter apartment name'
										onChange={handleInputChange}
										required
									/>
								</div>
							</div>

							<div className='sm:col-span-3'>
								<label
									htmlFor='owner_name'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Owner name
								</label>
								<div className='mt-2'>
									<input
										type='text'
										name='owner_name'
										id='owner_name'
										autoComplete='family-name'
										className='block w-full rounded-md border-0 px-2 text-sm py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
										value={accDetail.owner_name}
										onChange={handleInputChange}
										placeholder='Enter owner name'
										required
									/>
								</div>
							</div>

							<div className='sm:col-span-4'>
								<label
									htmlFor='email'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Email address
								</label>
								<div className='mt-2'>
									<input
										id='email'
										name='email'
										type='email'
										autoComplete='email'
										className='block w-full rounded-md border-0 px-2 text-sm py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
										value={accDetail.email}
										onChange={handleInputChange}
										placeholder='Enter email address'
										required
									/>
								</div>
							</div>

							<div className='col-span-full'>
								<label
									htmlFor='street-address'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Contact No.
								</label>
								<div className='mt-2'>
									<input
										type='number'
										name='contact1'
										id='contact1'
										className='block w-full rounded-md border-0 px-2 text-sm py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
										value={accDetail.contact1}
										onChange={handleInputChange}
										placeholder='Enter Phone Number'
										required
										maxLength='10'
									/>
								</div>
							</div>
							<div className='col-span-full'>
								<label
									htmlFor='street-address'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Alternate Phone No.
								</label>
								<div className='mt-2'>
									<input
										type='number'
										name='contact2'
										id='contact2'
										autoComplete='street-address'
										className='block w-full rounded-md border-0 px-2 text-sm py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
										value={accDetail.contact2}
										onChange={handleInputChange}
										placeholder='Optional'
									/>
								</div>
							</div>
							<div className='col-span-full'>
								<label
									htmlFor='street-address'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Address
								</label>
								<div className='mt-2'>
									<input
										type='text'
										name='address'
										id='street-address'
										autoComplete='street-address'
										className='block w-full rounded-md border-0 px-2 text-sm py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
										value={accDetail.address}
										onChange={handleInputChange}
										placeholder='Enter address here'
										required
									/>
								</div>
							</div>

							<div className='sm:col-span-2 sm:col-start-1'>
								<label
									htmlFor='locality'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Locality
								</label>
								<div className='mt-2'>
									<input
										type='text'
										name='locality'
										id='city'
										autoComplete='address-level2'
										className='block w-full rounded-md border-0 px-2 text-sm py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
										value={accDetail.locality}
										onChange={handleInputChange}
										placeholder='eg. Kirti nagar'
										required
									/>
								</div>
							</div>

							<div className='sm:col-span-2'>
								<label
									htmlFor='city'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									City
								</label>
								<div className='mt-2'>
									<input
										type='text'
										name='city'
										id='region'
										autoComplete='address-level1'
										className='block w-full rounded-md border-0 py-1.5 px-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
										value={accDetail.city}
										onChange={handleInputChange}
										placeholder='Enter city here'
										required
									/>
								</div>
							</div>

							<div className='sm:col-span-3'>
								<label
									htmlFor='state'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									State
								</label>
								<div className='mt-2'>
									<select
										id='state'
										name='state'
										autoComplete='state'
										className='block w-full rounded-md border-0 py-1.5 px-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-xs sm:text-sm sm:leading-6'
										value={accDetail.state}
										onChange={handleInputChange}
										required
									>
										<option value=''>Select state</option>
										<option>Chattisgarh</option>
										<option>Haryana</option>
									</select>
								</div>
							</div>

							<div className='sm:col-span-2'>
								<label
									htmlFor='total_accomation'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Total Accommodation
								</label>
								<div className='mt-2'>
									<input
										type='text'
										name='total_accomation'
										id='postal-code'
										autoComplete='postal-code'
										className='block w-full rounded-md border-0 py-1.5 px-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
										value={accDetail.total_accomation}
										onChange={handleInputChange}
										placeholder='eg 1, 2, 3'
										required
									/>
								</div>
							</div>

							<div className='sm:col-span-2'>
								<label
									htmlFor='tc'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Terms and Conditions
								</label>
								<div className='mt-2'>
									<input
										type='text'
										name='tc'
										id='postal-code'
										autoComplete='postal-code'
										className='block w-full px-2 text-sm rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
										value={accDetail.tc}
										onChange={handleInputChange}
										placeholder='Enter terms and conditons'
										required
									/>
								</div>
							</div>
							<div className='sm:col-span-2'>
								<label
									htmlFor='tenant'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Tenant
								</label>
								<div className='mt-2'>
									<select
										name='tenant'
										id='tenant'
										className='block w-full rounded-md border-0 py-1.5 text-gray-900 px-2 text-sm shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
										value={accDetail.tenant}
										onChange={handleInputChange}
										required
									>
										<option value=''>Select tenant type</option>
										<option value='boys'>Boys</option>
										<option value='girls'>Girls</option>
										<option value='family'>Family</option>
									</select>
								</div>
							</div>
						</div>
					</div>

					<div className='mt-6 flex items-center justify-end gap-x-6'>
						<button
							type='button'
							className='text-sm font-semibold text-gray-900 border border-red-500 rounded-md px-3 py-2'
							onClick={() => navigate(-1)}
						>
							Cancel
						</button>
						<button
							className={`flex justify-center text-white bg-red-500 border-0 py-1 md:py-2 items-center gap-2 px-4 text-sm focus:outline-none hover:bg-red-600 rounded-md leading-6 md:text-lg whitespace-nowrap transform transition-transform  active:scale-90 ${
								isLoading ? 'cursor-not-allowed' : ''
							}`}
							disabled={isLoading}
						>
							{isLoading ? (
								<>
									Saving <FaSpinner className='animate-spin text-white' />
								</>
							) : (
								'Save'
							)}
						</button>
					</div>
				</form>
			</Wrapper>
		</div>
	);
};

export default AddAccomodation;

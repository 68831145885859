import React from 'react';
import Wrapper from '../../components/Wrapper';
import image from '../../assets/popup.jpg';
import { Link } from 'react-router-dom';

const Cashback = () => {
	return (
		<div className='my-24'>
			<Wrapper>
				<div className='flex justify-center items-center overflow-hidden '>
					<div className='relative  rounded overflow-hidden'>
						<Link
							className='absolute bottom-0 right-0 m-10  px-8 py-3 font-semibold bg-red-600 rounded text-white text-sm transform transition-transform border border-black active:scale-90'
							to={'/registerOwner'}
						>
							Claim Now
						</Link>
						<div className='h-full flex justify-center items-center'>
							<img
								src={image}
								alt='pop up'
								className='object-cover'
							/>
						</div>
					</div>
				</div>
			</Wrapper>
		</div>
	);
};

export default Cashback;

import axios from 'axios';
import { domain } from '../../utils/domain';

export const FetchRoomsData = async (cityName) => {
	try {
		const response = await axios.get(`${domain}/accommodation_list/${cityName}`, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return response.data;
	} catch (error) {
		console.error(`Failed to get accommodation list: ${error.message}`);
		throw error;
	}
};

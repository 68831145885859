import axios from 'axios';
import { domain } from '../../utils/domain';

const headers = {
	Accept: 'application/json',
};

export const LoginUserOtpLess = async (userData) => {
	try {
		const response = await axios.post(`${domain}/app_login`, userData, headers);
		localStorage.removeItem('ownerToken');
		localStorage.removeItem('agentToken');
		localStorage.setItem('token', response.data.access_token);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

import React, { useEffect, useState } from 'react';
import Wrapper from '../Wrapper';
import { FaCalendar, FaCheckCircle, FaRegCalendarAlt, FaSpinner } from 'react-icons/fa';
import moment from 'moment';
import { assignVisitForAgent, fetchAgentsData } from '../../redux-store/slice/agentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWishlistedData } from '../../redux-store/slice/wishlistSlice';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const ScheduleVisitPopup = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { wishlistedPgs } = useSelector((state) => state.wishlist);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		dispatch(fetchWishlistedData());
	}, [dispatch]);

	const [showModal, setShowModal] = useState(false);
	const [selectedData, setSelectedData] = useState({
		date: '',
		visting_time: '',
	});
	const [timeSlots, setTimeSlots] = useState([]);
	const [dateSlots, setDateSlots] = useState([]);

	const dateFilter = (date) => {
		const currentTime = moment();
		const isLateAfternoon = currentTime.hour() >= 18;

		if (isLateAfternoon) {
			return date.isAfter(moment().startOf('day'));
		}

		return date.isSameOrAfter(moment().startOf('day'));
	};

	useEffect(() => {
		const startTime = moment('10:00 AM', 'h:mm A');
		const endTime = moment('6:30 PM', 'h:mm A');
		const timeSlotDuration = 0.5; // in hours
		// Generate time slots
		const generatedTimeSlots = [];
		let currentTime = moment(startTime);
		while (currentTime.isBefore(endTime)) {
			generatedTimeSlots.push(currentTime.format('h:mm A'));
			currentTime.add(timeSlotDuration, 'hours');
		}
		// Filter time slots based on selected date and current time
		const formattedSelectedDate = moment(selectedData.date, 'DD MMM');
		const currentDate = moment();
		if (formattedSelectedDate.isSame(currentDate, 'day')) {
			const currentHour = currentDate.hour();
			generatedTimeSlots.splice(0, currentHour - startTime.hour());
		}

		setTimeSlots(generatedTimeSlots);
	}, [selectedData.date]);

	useEffect(() => {
		// Generate date slots
		const formattedDates = Array.from({ length: 3 }, (_, index) =>
			moment()
				.add(index + (dateFilter(moment().add(index, 'days')) ? 1 : 0), 'days')
				.format('DD MMM')
		);

		setDateSlots(formattedDates);
	}, []);

	const handleConfirm = async () => {
		setIsLoading(true);
		if (
			selectedData.date !== undefined &&
			selectedData.date !== '' &&
			selectedData.visting_time !== undefined &&
			selectedData.visting_time !== '' &&
			wishlistedPgs?.[0]?.city !== undefined &&
			wishlistedPgs?.[0]?.city !== '' &&
			wishlistedPgs?.some((obj) => obj.locality !== undefined && obj.locality.length > 0)
		) {
			const city = wishlistedPgs[0].city;
			const localitiesArray = wishlistedPgs?.map((obj) => obj.locality);
			const formattedDate = moment(selectedData.date, 'DD MMM').format('YYYY-MM-DD');
			const formattedTime = moment(selectedData.visting_time, 'h:mm A').format('HH:mm:ss');

			const postData = {
				date: formattedDate,
				visting_time: formattedTime,
				city: city,
				locality: localitiesArray,
			};

			try {
				// Dispatch the new action to assign a visit for the agent
				const data = await dispatch(assignVisitForAgent(postData));
				if (data.error) {
					toast.error(data.error.message);
					setIsLoading(false);
					return;
				}
				toast.success('Visit Scheduled Successfully');
				navigate('/');
				await dispatch(fetchWishlistedData());
				// After assigning the visit, update the agents data
				await dispatch(fetchAgentsData());
				setIsLoading(false);
				setShowModal(false);
			} catch (error) {
				console.error(error);
				setIsLoading(false);
			}
		} else {
			toast.error('Fill Date and time first', 'error');
			setIsLoading(false);
		}
	};

	const [scale, setScale] = useState(1);

	useEffect(() => {
		const interval = setInterval(() => {
			// Toggle between 1 and 1.25
			setScale((prevScale) => (prevScale === 1 ? 1.03 : 1));
		}, 1500); // Adjust the interval as needed

		return () => clearInterval(interval);
	}, []);

	const handleDateSlotClick = (selectedDate) => {
		// Reset the previously selected date in dateSlots
		const updatedDateSlots = dateSlots.map((date) => (date === selectedData.date ? selectedData.date : date));

		setSelectedData({ ...selectedData, date: selectedDate });
		setDateSlots(updatedDateSlots);
	};

	const handleCalenderChange = (date) => {
		const formattedDate = moment(date).format('DD MMM');

		setSelectedData({ ...selectedData, date: formattedDate });
	};

	const handleCancel = () => {
		setShowModal(false);
		setSelectedData({
			...selectedData,
			date: '',
			visting_time: '',
		});
	};

	return (
		<>
			<div className={`fixed bottom-0 right-0 mr-6 z-40  mb-24 ${wishlistedPgs?.length ? '' : 'hidden'}`}>
				<Wrapper>
					<button
						style={{ transform: `scale(${scale})`, transition: 'transform 0.3s ease' }}
						className='text-sm  text-white  flex  gap-2 bg-red-500 px-3 py-1 hover:bg-red-600 rounded-full items-center justify-center'
						onClick={() => setShowModal(true)}
					>
						Schedule Visit
						<FaRegCalendarAlt className='mb-0.5' />
					</button>
					{showModal && (
						<div className='fixed inset-0  z-40 bg-black backdrop-blur-sm bg-opacity-35 flex items-center justify-center'>
							<div className='bg-white px-4 py-4 rounded-md shadow-md mx-4 w-full space-y-8 md:w-96'>
								<h2 className='border-b-2 py-2 border-gray-200 font-medium text-red-500'>You are visiting On</h2>
								<div className='mt-4 space-y-2'>
									<h3 className='text-gray-700 text-sm mb-1 font-medium'>Select Date</h3>
									<div className='flex flex-wrap gap-2'>
										{dateSlots?.map((date, index) => (
											<div
												key={index}
												className={`text-xs border  rounded-full py-1 px-2 cursor-pointer text-red-500   ${
													selectedData.date === date
														? 'bg-gray-300 border-gray-300'
														: 'border-gray-300'
												}`}
												onClick={() => handleDateSlotClick(date)}
											>
												{date}
											</div>
										))}
										<div className='relative flex items-center'>
											{selectedData.date && (
												<div
													className={`text-xs border rounded-full py-1 px-2 cursor-pointer bg-red-500 text-white flex items-center gap-1`}
												>
													{selectedData.date ? selectedData.date : ''}
													<FaCheckCircle />
												</div>
											)}
											<ReactDatePicker
												selected={
													selectedData.date
														? moment(selectedData.date, 'DD/MM/YYYY').toDate()
														: null
												}
												onChange={(date) => handleCalenderChange(date)}
												className='w-10 border-none border-0 focus:ring-transparent  text-white text-sm rounded-full px-2 cursor-pointer'
												minDate={moment().add(1, 'days').toDate()}
											/>
											<FaCalendar
												className='absolute inset-y-0 right-2 top-1 flex items-center pointer-events-none cursor-pointer'
												size={20}
												color='red'
											/>
										</div>
									</div>
								</div>
								<div className='mt-4'>
									<h3 className='text-gray-700 text-sm mb-1 font-medium'>Select Time</h3>
									<div className='flex flex-col gap-2 '>
										<select
											name='time'
											className='border border-gray-400 text-sm text-gray-800 rounded px-2 py-0.5'
											value={selectedData.visting_time}
											onChange={(e) =>
												setSelectedData((prevData) => ({
													...prevData,
													visting_time: e.target.value,
												}))
											}
										>
											<option value=''>Select time</option>
											{timeSlots?.map((time, index) => (
												<option
													key={index}
													value={time}
													className='text-sm'
												>
													{time}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className='mt-4 flex justify-end gap-4 border-t border-gray-300 pt-4'>
									<button
										className='border border-red-500 px-2 py-1 text-sm rounded-full'
										onClick={handleCancel}
									>
										Cancel
									</button>
									<button
										className={`flex justify-center text-white bg-red-500 border-0 py-1 md:py-2 items-center gap-2 px-4 text-sm focus:outline-none hover:bg-red-600 rounded-full md:text-lg whitespace-nowrap transform transition-transform  active:scale-90 ${
											isLoading ? 'cursor-not-allowed' : ''
										}`}
										onClick={handleConfirm}
										disabled={isLoading}
									>
										Confirm
										{isLoading && <FaSpinner className='animate-spin text-white' />}
									</button>
								</div>
							</div>
						</div>
					)}
				</Wrapper>
			</div>
		</>
	);
};

export default ScheduleVisitPopup;

import React from 'react';
import { FaCity, FaHome, FaPhoneAlt, FaUser } from 'react-icons/fa';
import { CgDetailsMore } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import moment from 'moment';

const AgentTodayOrderCard = ({ order }) => {
	const formattedTime = moment(order?.start_time, 'HH:mm:ss').format('h A');
	return (
		<div className='w-full py-4 px-3 border mb-3 shadow-md rounded'>
			<div className='flex flex-col gap-4'>
				<div className='bg-gray-100 flex justify-around  p-2 rounded-sm'>
					<div>
						<span className='font-semibold'>Order id - </span>
						<span>{order?.orderno}</span>
					</div>
					<div>
						<span className='font-semibold'>Time - </span>
						<span>{formattedTime}</span>
					</div>
				</div>
				<div className='border rounded p-3 flex gap-2 flex-col whitespace-nowrap'>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<FaUser color='red' />

						<span className='font-medium text-gray-600'> Name - </span>
						<span className='text-sm font-semibold text-gray-900'>{order.name}</span>
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<FaPhoneAlt color='red' />

						<span className='font-medium text-gray-600'>Contact - </span>
						<span className='text-sm font-semibold text-gray-900'>{order.contact}</span>
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<FaHome
							color='red'
							size={18}
						/>

						<span className='font-medium text-gray-600'>No. of sites - </span>
						<span className='text-sm font-semibold text-gray-900'>{order.no_of_visit}</span>
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<FaCity
							color='red'
							size={18}
						/>

						<span className='font-medium text-gray-600'>Place - </span>
						<span className='text-sm font-semibold text-gray-900'>{order.service_area}</span>
					</div>
				</div>
				{order.orderno && (
					<div className='w-full'>
						<Link
							className='bg-red-500 hover:bg-red-600 px-4 py-1 rounded-full text-white uppercase text-sm
                    flex items-center justify-center gap-2 w-full'
							to={`/agentOrderDetails/${order.orderno}`}
						>
							<span>View Details</span>
							<CgDetailsMore size={20} />
						</Link>
					</div>
				)}
			</div>
		</div>
	);
};

export default AgentTodayOrderCard;

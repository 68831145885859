import React from 'react';
import Wrapper from '../components/Wrapper';

const Reviews = () => {
	return (
		<div className='my-24'>
			<Wrapper>
				<div>
					<h1 className='text-xl text-gray-800 font-semibold text-center'>Reviews</h1>
				</div>
			</Wrapper>
		</div>
	);
};

export default Reviews;

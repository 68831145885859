import React, { useState } from 'react';
import { FaDirections, FaHome, FaPhoneAlt, FaRegAddressCard, FaRupeeSign, FaUser } from 'react-icons/fa';
import { MdFeedback } from 'react-icons/md';
import { Carousel } from 'react-responsive-carousel';
import { IoSend } from 'react-icons/io5';
import { PutDealDone, PutFeedBackToLandlord } from '../../api/agent/agent';

const AgentOrderDetailsCard = ({ order, orderid }) => {
	const [showDealDoneModal, setShowDealDoneModal] = useState(false);
	const [showFeedBackModal, setShowFeedBackModal] = useState(false);
	const [feedBack, setFeedBack] = useState('');

	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleLocation = () => {
		const latitude = order.latitude;
		const longitude = order.longitude;
		const googleMapsURL = `https://www.google.com/maps?daddr=${latitude},${longitude}`;
		window.open(googleMapsURL);
	};

	// Parse the JSON string in the images property
	const imagesObject = JSON.parse(order.images);

	// Extract image URLs from the parsed object
	const imageUrls = Object.values(imagesObject);

	const perksArray = order?.perks.split(',');

	const putDealDone = async () => {
		setIsError(false);
		setIsLoading(true);
		try {
			await PutDealDone(orderid, order.entery_id);

			setIsLoading(false);
			setShowDealDoneModal(false);
		} catch (error) {
			console.error(error);
			setIsError(true);
			setIsLoading(false);
		}
	};

	const handleSendFeedback = async (e) => {
		e.preventDefault();
		setIsError(false);
		setIsLoading(true);
		try {
			await PutFeedBackToLandlord(feedBack, order.entery_id);

			setIsLoading(false);
			setShowDealDoneModal(false);
		} catch (error) {
			console.error(error);
			setIsError(true);
			setIsLoading(false);
		}
	};

	return (
		<section className='text-gray-600 body-font overflow-hidden'>
			<div className='container px-5 py-8 mx-auto  '>
				<div className='flex flex-col justify-around md:flex-row md:items-center'>
					<div className='lg:w-1/3 w-full lg:h-auto my-2'>
						<Carousel
							showThumbs={false}
							infiniteLoop={true}
							autoPlay={true}
							interval={1000}
							showStatus={false}
						>
							{imageUrls.map((imageUrl, index) => {
								const image = `https://res.cloudinary.com/du5mxasbv/image/upload/${imageUrl}`;

								return (
									<img
										key={index}
										alt={`Room ${index + 1}`}
										className='h-full w-full object-cover object-center rounded '
										src={`${image !== null ? image : 'https://dummyimage.com/400x400'}`}
									/>
								);
							})}
						</Carousel>
					</div>
					<div className='lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0'>
						<h2 className='text-sm title-font text-gray-500 tracking-widest'>
							{order.locality}, {order.city}
						</h2>
						<h1 className='text-gray-900 text-xl md:text-3xl title-font font-medium mb-1'> {order.apartment_name}</h1>
						<div className='flex flex-wrap gap-2 pb-1 my-2'>
							{perksArray.map((perk) => (
								<span
									key={perk}
									className='bg-red-50 text-gray-800 px-1 md:px-3 py-[1px] md:py-2 rounded-full text-sm md:text-lg flex items-center text-center'
								>
									{perk}
								</span>
							))}
						</div>
						<div className=' flex flex-col gap-2 my-2'>
							<div className='flex items-center gap-3 pl-1'>
								<FaHome
									size={16}
									color='red'
								/>
								<span className='font-medium text-gray-600 text-sm'> Room ID - </span>
								<span className='text-sm font-semibold text-gray-900'>{order.roomid}</span>
							</div>
							<div className='flex items-center gap-3 rounded pl-1'>
								<FaUser
									size={16}
									color='red'
								/>

								<span className='font-medium text-sm text-gray-600'>Owner Name - </span>
								<span className='text-sm font-semibold text-gray-900'>{order.owner_name}</span>
							</div>
							<div className='flex items-center gap-3  pl-1'>
								<FaPhoneAlt
									size={16}
									color='red'
								/>
								<span className='font-medium text-gray-600 text-sm'>Contact 1- </span>
								<span className='text-sm font-semibold text-gray-900'>{order.contact1}</span>
							</div>
							<div className='flex items-center gap-3 pl-1'>
								<FaPhoneAlt
									size={16}
									color='red'
								/>

								<span className='font-medium text-gray-600 text-sm'>Contact 2 - </span>
								<span className='text-sm font-semibold text-gray-900'>{order.contact2}</span>
							</div>
							<div className='flex items-center gap-3 pl-1'>
								<FaRupeeSign
									size={16}
									color='red'
								/>
								<span className='font-medium text-gray-600 text-sm'>Rent Price - </span>
								<span className='text-sm font-semibold text-gray-900'>
									{' '}
									&#8377;{order.rent_price}/{order.rate}
								</span>
							</div>
							<div className='flex  gap-3 pl-1'>
								<span>
									<FaRegAddressCard
										size={16}
										color='red'
									/>
								</span>
								<span className='font-medium text-gray-600 text-sm whitespace-nowrap'> Address - </span>
								<span className='text-sm font-semibold text-gray-900'>{order.address}</span>
							</div>
							<div className='flex justify-end mr-3'>
								<button
									className='text-xs border border-red-500 px-2  py-[2px] rounded flex items-center gap-2 hover:bg-gray-100'
									onClick={handleLocation}
								>
									<span className='text-red-800'>Get directions</span>
									<FaDirections color='red' />
								</button>
							</div>
						</div>
						<div className='flex gap-2 mt-4 md:mt-10'>
							<button
								className='flex justify-center text-red-500 py-1 md:py-2 items-center gap-2 px-2 focus:outline-none hover:bg-red-100 rounded w-1/2 border border-red-500'
								onClick={() => setShowFeedBackModal(true)}
							>
								<>
									<span className='md:text-lg text-sm'>Feedback </span>
									<MdFeedback />
								</>
							</button>
							<button
								className='flex justify-center w-1/2 text-white bg-red-500 border-0 py-1 md:py-2 items-center gap-2 px-2 focus:outline-none hover:bg-red-600 rounded'
								onClick={() => setShowDealDoneModal(true)}
							>
								<>
									<span className='md:text-lg text-sm'>Deal Done ?</span>
								</>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div
				className={`fixed inset-0 z-50  flex items-center justify-center bg-black bg-opacity-50 ${showDealDoneModal ? '' : 'hidden'}`}
				id='dealDoneModal'
			>
				<div className='bg-white p-8 rounded shadow-lg w-full sm:w-96'>
					<div className='text-xl font-bold mb-4 mx3'>Deal Confirmation</div>
					<p className='text-gray-700 mb-4'>Are you sure the deal for Room ID {order.roomid} is done?</p>
					<div className='flex justify-end gap-2'>
						<button
							className='text-red-500 mr-2 hover:text-red-700 border border-red-500 px-2 rounded'
							onClick={() => setShowDealDoneModal(false)}
						>
							Cancel
						</button>
						<button
							className='bg-red-500 px-2  text-white hover:bg-red-600 rounded'
							onClick={() => putDealDone()}
						>
							{isLoading ? 'Loading ...' : 'Confirm'}
						</button>
					</div>
				</div>
			</div>
			<div
				className={`fixed inset-0 z-50  flex items-center justify-center bg-black bg-opacity-50 ${showFeedBackModal ? '' : 'hidden'}`}
				id='dealDoneModal'
			>
				<div className='bg-white p-8 rounded shadow-lg w-full sm:w-96 relative'>
					<button
						className='absolute top-2 right-2'
						onClick={() => setShowFeedBackModal(false)}
					>
						Close
					</button>
					<div className='text-lg font-semibold mb-4 mx-3'>Send Feedback to Landlord</div>
					<form
						action=''
						className='flex justify-center gap-2'
						onSubmit={handleSendFeedback}
					>
						<input
							type='text'
							className='border border-red-500 rounded-sm px-2 py-[1px]'
							placeholder='Enter feedback here'
							value={feedBack}
							onChange={(e) => setFeedBack(e.target.value)}
						/>
						<button
							className='bg-red-500 px-2 text-white rounded-sm py-[1px] hover:bg-red-600 flex items-center gap-2'
							type='submit'
						>
							{isLoading ? (
								'Loading ...'
							) : (
								<>
									<span>Send</span>
									<IoSend size={14} />
								</>
							)}
						</button>
					</form>
				</div>
			</div>
		</section>
	);
};

export default AgentOrderDetailsCard;

import React from 'react';
import { FaCalendar, FaHeart, FaHome, FaRupeeSign } from 'react-icons/fa';
import Wrapper from '../Wrapper';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Footer = () => {
	const location = useLocation();
	const { wishlistedPgs } = useSelector((state) => state.wishlist);

	const token = localStorage.getItem('token');
	const ownerToken = localStorage.getItem('ownerToken');
	const agentToken = localStorage.getItem('agentToken');

	const isPageActive = (path) => {
		return location.pathname === path;
	};

	return (
		<div className='bg-white fixed bottom-0 left-0 right-0 h-16 w-full border-t border-t-gray-300 shadow-2xl z-30'>
			<Wrapper>
				<div className='flex h-full'>
					<Link
						className={`flex flex-col items-center gap-1 cursor-pointer  w-1/3 h-16 justify-center ${agentToken || ownerToken ? 'hidden' : ''} ${
							isPageActive('/cashback') ? 'bg-red-500 text-white' : 'bg-white text-red-500'
						}`}
						to={'/cashback'}
					>
						<FaRupeeSign size={20} />
						<span className='text-sm'>Cashback</span>
					</Link>
					<Link
						className={`flex flex-col items-center gap-1 cursor-pointer  w-1/3 h-16 justify-center ${ownerToken || agentToken ? '' : 'hidden'} ${
							isPageActive('/studentBookings') || isPageActive('/ownerBookingSchedules') ? 'bg-red-500 text-white' : 'bg-white text-red-500'
						}`}
						to={ownerToken ? '/ownerBookingSchedules' : agentToken ? '/' : '/ownerLogin'}
					>
						<FaCalendar size={20} />
						<span className='text-sm'>Schedule</span>
					</Link>
					<Link
						className={`flex flex-col items-center gap-1 cursor-pointer  w-1/3 h-16 justify-center ${
							isPageActive('/') || isPageActive('/agentTodayOrder') || isPageActive('/accomodationList')
								? 'bg-red-500 text-white'
								: 'bg-white text-red-500'
						}`}
						to={agentToken ? '/agentTodayOrder' : ownerToken ? '/accomodationList' : '/'}
					>
						<FaHome size={20} />
						<span className='text-sm'>Home</span>
					</Link>
					<Link
						className={`flex flex-col items-center gap-1 cursor-pointer  w-1/3 h-16 justify-center ${
							isPageActive('/wishlist') ? 'bg-red-500 text-white' : 'bg-white text-red-500'
						} ${ownerToken || agentToken ? 'hidden' : ''}`}
						to='/wishlist'
					>
						<div className='relative'>
							{wishlistedPgs?.length > 0 && (
								<span
									className={`absolute -top-[6px]  rounded-full  w-[15px] h-[15px] -right-2 flex items-center  border border-red-500 justify-center text-center text-[9px] font-medium  ${
										isPageActive('/wishlist') ? 'bg-white text-red-500' : ' bg-red-500 text-white'
									}`}
								>
									{wishlistedPgs?.length}
								</span>
							)}
							<FaHeart size={20} />
						</div>
						<span className=' text-sm'>Wishlist</span>
					</Link>
					<Link
						className={`flex flex-col items-center gap-1 cursor-pointer w-1/3 h-16 justify-center ${
							isPageActive('/ownerPaymentHistory') || isPageActive('/agentPaymentHistory') ? 'bg-red-500 text-white' : 'bg-white text-red-500'
						} ${ownerToken || agentToken ? '' : 'hidden'}`}
						to={ownerToken ? '/ownerPaymentHistory' : '/agentPaymentHistory'}
					>
						<FaRupeeSign size={20} />
						<span className='text-sm'>Payment</span>
					</Link>
				</div>
			</Wrapper>
		</div>
	);
};

export default Footer;

import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Wrapper from '../Wrapper';

const MainCarousel = () => {
	const img1 = 'https://res.cloudinary.com/du5mxasbv/image/upload/v1704432401/IMG_20240105_105545_xwhzcu.jpg';
	const img2 = 'https://res.cloudinary.com/du5mxasbv/image/upload/v1704038298/qgwbeohlowq7nnfwmvaf.jpg';
	const img3 = 'https://res.cloudinary.com/du5mxasbv/image/upload/v1704038227/jkismyhnqutmsgpjuotr.jpg';
	const imgsArr = [img1, img2, img3];

	const customRenderIndicator = (onClickHandler, isSelected, index) => (
		<li
			className={`w-6 h-[3px] rounded-sm inline-block my-0 mx-1 cursor-pointer ${isSelected ? 'bg-red-800' : 'bg-red-200'}`}
			onClick={onClickHandler}
			onKeyDown={onClickHandler}
			key={index}
		/>
	);

	return (
		<div className='w-full md:h-4/5'>
			<Wrapper>
				<Carousel
					showThumbs={false}
					infiniteLoop={true}
					autoPlay={true}
					renderIndicator={customRenderIndicator}
					interval={4000}
					showStatus={false}
				>
					{imgsArr?.map((image, index) => (
						<div
							key={index}
							className='h-full w-full'
						>
							<img
								src={image}
								alt='img'
								className='object-cover w-full max-h-[80vh] mx-auto'
							/>
						</div>
					))}
				</Carousel>
			</Wrapper>
		</div>
	);
};

export default MainCarousel;

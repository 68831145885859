import axios from 'axios';
import { domain } from '../../utils/domain';

const ownerToken = localStorage.getItem('ownerToken');

const headers = {
	accept: 'application/json',
	Authorization: 'Bearer ' + ownerToken,
};

export const FetchAccomodationDetails = async () => {
	if (ownerToken) {
		try {
			const response = await axios.get(`${domain}/room_list`, { headers });
			return response.data;
		} catch (error) {
			console.error('Failed to get accomodation details :' + error.message);
			throw error;
		}
	}
};

export const UpdateRoomDetails = async (endpoint) => {
	if (ownerToken) {
		try {
			const response = await axios.put(`${domain}/${endpoint}`, null, { headers });
			return response.data;
		} catch (error) {
			console.error('Error in updating Room details :' + error.message);
			throw error;
		}
	}
};

export const GetOwnerBookingSchedules = async () => {
	if (ownerToken) {
		try {
			const response = await axios.get(`${domain}/visting_client`, {
				headers,
			});
			return response.data;
		} catch (error) {
			console.error('Error in getting owner booking schedules :' + error.message);
			throw error;
		}
	}
};

export const GetOwnerPaymentHistory = async () => {
	if (ownerToken) {
		try {
			const response = await axios.get(`${domain}/payment-status`, { headers });
			return response.data;
		} catch (error) {
			console.error('Error in getting owner Payment History :' + error.message);
			throw error;
		}
	}
};

import React from 'react';
import { MdAccountCircle, MdGrade } from 'react-icons/md';
import Wrapper from '../Wrapper';
import { Link } from 'react-router-dom';

const ReviewsSlider = () => {
	const reviewArr = [
		{
			name: 'Tanvi Sharma',
			college: 'Shri Shankaracharya Technical Campus',
			review: 'This app is a game-changer for finding a PG! Super easy to use, has cool filters, and the support team is always on point. A must-have if you want to keep your accommodation search chill!',
			rating: 5,
		},
		{
			name: 'Sahil Kumar',
			college: 'Chhattisgarh Swami Vivekanand Technical University',
			review: 'Dealing with the whole PG accommodation hunt, this app really came through for me, making things way easier and giving me a hand when I needed it the most',
			rating: 5,
		},
		{
			name: 'Manjeet Verma',
			college: 'Hemchand Yadav Vishwavidyalaya',
			review: 'Hunting for a PG is a breeze with this app! It hooks you up with tailored suggestions, texts back lightning fast, and the design is super user-friendly. Makes the whole search game feel like a cakewalk!"',
			rating: 4,
		},
	];
	return (
		<Wrapper>
			<div className='mt-8 md:mt-16 bg-gray-50 py-8'>
				<div className='flex flex-col text-center w-full mb-4'>
					<h1 className='sm:text-2xl text-xl font-semibold title-font text-gray-700'>Reviews From Customers</h1>
				</div>
				<div className='flex overflow-x-auto  gap-4 mx-4'>
					{reviewArr?.map((reviewItem) => (
						<div
							className='mb-2 bg-white border  p-4 md:p-8 shadow-md'
							key={reviewItem.name}
						>
							<div className='flex flex-col items-start justify-center  w-[320px] md:w-[420px]'>
								<div className='flex items-center w-full justify-between'>
									<div className='flex items-center gap-2 md'>
										<div className='text-gray-600 text-xl md:text-4xl'>
											<MdAccountCircle />
										</div>
										<div className='flex flex-col'>
											<h2 className='title-font font-medium text-sm md:text-lg'>{reviewItem.name}</h2>
											<span className='text-[11px] text-gray-800 md:text-sm'>{reviewItem.college}</span>
										</div>
									</div>

									<div className='mr-0 text-sm flex text-yellow-500'>
										{[...Array(reviewItem.rating)].map((_, index) => (
											<MdGrade key={index} />
										))}
									</div>
								</div>
							</div>

							<div className='flex-wrap mt-3'>
								<p className=' text-[13px] md:text-base text-gray-500'>{reviewItem.review}</p>
							</div>
						</div>
					))}
					<div className='mb-2 bg-white p-3 shadow-md'>
						<Link
							className='flex flex-col h-full gap-2 items-center mb-3 justify-center cursor-pointer font-semibold w-64'
							to='/reviews'
						>
							<p>And Many More &gt;&gt;</p>
							<p className='text-red-500'>✍Have a say in your stay</p>
						</Link>
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

export default ReviewsSlider;

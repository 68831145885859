// wishlistSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetWishlistedRooms, PostWishlistedRoom, RemoveWishlistedRoom } from '../../api/student/wishlist';

export const addToWishlist = createAsyncThunk('wishlist/addToWishlist', async (postData, { dispatch }) => {
	const data = await PostWishlistedRoom('wishlist', postData);
	if (data) {
		dispatch(fetchWishlistedData());
	}
	return data;
});

export const removeWishlistRoom = createAsyncThunk('wishlist/removeWishlistRoom', async (entery_id, { dispatch }) => {
	try {
		const data = await RemoveWishlistedRoom('remove_wishlist', entery_id);
		if (data) {
			dispatch(fetchWishlistedData());
		}
		return data;
	} catch (error) {
		console.error(error);
		throw error;
	}
});

export const fetchWishlistedData = createAsyncThunk('wishlist/fetchWishlistedData', async () => {
	const data = await GetWishlistedRooms();
	return data;
});

const wishlistSlice = createSlice({
	name: 'wishlist',
	initialState: {
		isLoading: false,
		wishlistedPgs: [],
		isError: false,
	},
	extraReducers: (builder) => {
		builder.addCase(addToWishlist.pending, (state) => {
			state.isLoading = true;
		})
			.addCase(addToWishlist.fulfilled, (state) => {
				state.isLoading = false;
			})
			.addCase(addToWishlist.rejected, (state) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(removeWishlistRoom.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(removeWishlistRoom.fulfilled, (state) => {
				state.isLoading = false;
			})
			.addCase(removeWishlistRoom.rejected, (state) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(fetchWishlistedData.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchWishlistedData.fulfilled, (state, action) => {
				state.isLoading = false;
				state.wishlistedPgs = action.payload;
			})
			.addCase(fetchWishlistedData.rejected, (state) => {
				state.isLoading = false;
				state.isError = true;
			});
	},
});

export default wishlistSlice.reducer;

import React from 'react';

const Step = ({ formData, handleInputChange, label, name, placeholder, isError }) => (
	<div className='flex flex-col'>
		<label
			htmlFor={name}
			className='font-semibold text-lg text-red-500 my-2 text-center'
		>
			{label}
		</label>
		<input
			type='text'
			name={name}
			value={formData[name]}
			onChange={handleInputChange}
			placeholder={placeholder}
			className='border border-red-500 rounded-md px-3 py-2 my-1 text-sm'
			required
		/>
		{isError && !formData[name].trim() && <span className='text-red-500 text-sm'>Enter a valid {name.replace('_', ' ')}</span>}
	</div>
);

export default Step;

import React, { useEffect, useState } from 'react';
import AgentPaymentHistoryCard from '../../components/AgentSide/AgentPaymentHistoryCard';
import Wrapper from '../../components/Wrapper';
import Loader from '../../components/Loader/Loader';
import { PiSmileySadThin } from 'react-icons/pi';
import { FetchAgentPaymentHistory } from '../../api/agent/agent';

const AgentPaymentHistory = () => {
	const [agentPaymentHistory, setAgentPaymentHistory] = useState([]);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [paymentStatusFilter, setPaymentStatusFilter] = useState('all'); // 'all', 'pending', 'paid'
	const [uniqueStatusValues, setUniqueStatusValues] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			setIsError(false);
			setIsLoading(true);
			try {
				const data = await FetchAgentPaymentHistory();
				setAgentPaymentHistory(data);
				// Extract unique status values
				const statusValues = Array.from(new Set(data.map((payment) => payment.payment_status)));
				setUniqueStatusValues(['all', ...statusValues]);
				setIsLoading(false);
			} catch (error) {
				console.error(error);
				setIsError(true);
				setIsLoading(false);
			}
		};
		fetchData();
	}, []);

	// Calculate total payment amount for orders with "paid" status
	const totalPaidAmount = agentPaymentHistory?.filter((payment) => payment.payment_status === 'paid').reduce((total, payment) => total + (payment.payment_amount + (payment.bonus || 0)), 0);

	// Filter payments based on payment status
	const filteredPayments = paymentStatusFilter === 'all' ? agentPaymentHistory : agentPaymentHistory?.filter((payment) => payment.payment_status === paymentStatusFilter);

	return (
		<div className='my-24 mx-4'>
			<Wrapper>
				<h1 className='text-center text-xl text-gray-800 font-semibold mb-3'>Your Payment History</h1>
				{isError ? (
					<div className='text-center mt-6 text-red-500'>Network Error, Please try again</div>
				) : (
					<div className='flex flex-col'>
						{isLoading && (
							<div className='flex justify-center'>
								<Loader />
							</div>
						)}

						<div className='w-full py-4 px-1 border mb-8 shadow-md rounded bg-red-100'>
							<div className='flex items-center p-1 gap-3 rounded justify-center'>
								<span className='font-medium text-sm text-gray-600'> Total Earning - </span>
								<span className='text-sm font-semibold text-gray-900'>Rs.{totalPaidAmount}</span>
							</div>
						</div>

						{/* FILTERS */}
						<div className='flex  space-x-2 mb-4'>
							{uniqueStatusValues.map((status) => (
								<button
									key={status}
									className={`px-2 py-1 rounded-full text-xs ${
										paymentStatusFilter === status
											? 'bg-red-500 text-white'
											: 'bg-white text-red-500 border border-red-500'
									}`}
									onClick={() => setPaymentStatusFilter(status)}
								>
									{status}
								</button>
							))}
						</div>

						{filteredPayments?.length > 0 ? (
							<>
								{filteredPayments.map((payment) => (
									<AgentPaymentHistoryCard
										key={payment.orderno}
										payment={payment}
									/>
								))}
							</>
						) : (
							<div className={`w-full flex flex-col items-center justify-center mt-24 px-12 gap-8 ${isLoading ? 'hidden' : ''}`}>
								<PiSmileySadThin
									size={68}
									color='gray'
								/>
								<div className='flex flex-col items-center gap-1'>
									<p className='text-gray-500 font-medium text-center'>You don't have any Payment history</p>
								</div>
							</div>
						)}
					</div>
				)}
			</Wrapper>
		</div>
	);
};

export default AgentPaymentHistory;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const OwnerPrivateRoute = (props) => {
	const navigate = useNavigate();
	const { Component } = props;
	useEffect(() => {
		const ownerToken = localStorage.getItem('ownerToken');
		if (!ownerToken) {
			// Token is not available, navigate to user login page
			navigate('/ownerLogin');
		}
	}, [navigate]);

	return <Component />;
};

export default OwnerPrivateRoute;

import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Wrapper from '../../components/Wrapper';

const Success = () => {
	const navigate = useNavigate();
	const { roomid } = useParams();
	console.log(roomid);

	const handleNavigateToNewAccomodation = () => {
		localStorage.removeItem('accidLocal');
		navigate('/addAccomodation');
	};
	return (
		<div className='mt-28 mx-4'>
			<Wrapper>
				<div className='flex flex-col gap-8'>
					<h3 className='font-semibold text-center text-green-700 flex items-center gap-2 mx-auto'>
						Room added successfully
						<FaCheckCircle />
					</h3>
					<p className='text-center font-medium text-lg'>Now create video for {roomid} and send it to admin</p>
					<div className='flex flex-col justify-center items-center my-4 gap-4'>
						<Link
							className='bg-red-500 px-2 py-1 rounded-full text-sm text-white'
							to='/addRoom'
						>
							Add new room
						</Link>
						or
						<button
							className='border border-red-500 px-2 py-1 rounded-full text-sm'
							onClick={handleNavigateToNewAccomodation}
						>
							Add new accomodation
						</button>
					</div>
				</div>
			</Wrapper>
		</div>
	);
};

export default Success;

import axios from 'axios';
import { domain } from '../../utils/domain';

const headers = {
	Accept: 'application/json',
};

export const LoginOwnerOtpLess = async (ownerData) => {
	try {
		const response = await axios.post(`${domain}/app_login`, ownerData, headers);
		localStorage.setItem('ownerToken', response.data.access_token);
		return response.data;
	} catch (error) {
		console.error(error.message);
		throw error;
	}
};

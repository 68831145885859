import React, { useEffect, useState } from 'react';
import OwnerPaymentHistoryCard from '../../components/OwnerSide/OwnerPaymentHistoryCard';
import Wrapper from '../../components/Wrapper';
import { GetOwnerPaymentHistory } from '../../api/owner/ownerdata';
import Loader, { SkeletonLoader2 } from '../../components/Loader/Loader';

const OwnerPaymentHistory = () => {
	const [paymentHistory, setPaymentHistory] = useState([]);
	const [statusFilter, setStatusFilter] = useState('all');
	const [uniqueStatusValues, setUniqueStatusValues] = useState([]);

	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const fetchData = async () => {
		setIsError(false);
		try {
			setIsLoading(true);
			const data = await GetOwnerPaymentHistory();
			console.log(data);
			setPaymentHistory(data);

			// Extract unique status values
			const statusValues = Array.from(new Set(data.map((payment) => payment.status)));
			setUniqueStatusValues(['all', ...statusValues]);

			setIsLoading(false);
		} catch (error) {
			console.error(error);
			setIsError(true);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	// Filter payments based on payment status
	const filteredPayments = statusFilter === 'all' ? paymentHistory : paymentHistory?.filter((payment) => payment.status === statusFilter);

	return (
		<div className='my-24 mx-4'>
			<Wrapper>
				<h1 className='text-center font-semibold text-gray-800 text-xl'>Your Payment History</h1>
				{isError ? (
					<div className='text-center mt-6 text-red-500'>Network Error, Please try again</div>
				) : (
					<div className='flex flex-col'>
						{isLoading ? (
							<SkeletonLoader2 />
						) : (
							<>
								{/* FILTERS */}
								<div className='flex  space-x-2 my-4'>
									{uniqueStatusValues.map((status) => (
										<button
											key={status}
											className={`px-2 py-1 rounded-full text-xs ${
												statusFilter === status
													? 'bg-red-500 text-white'
													: 'bg-white text-red-500 border border-red-500'
											}`}
											onClick={() => setStatusFilter(status)}
										>
											{status}
										</button>
									))}
								</div>
								<div className='grid md:grid-cols-3 gap-8 grid-cols-1'>
									{filteredPayments?.map((payment) => (
										<OwnerPaymentHistoryCard
											key={payment.entery_id}
											payment={payment}
										/>
									))}
								</div>
							</>
						)}
					</div>
				)}
			</Wrapper>
		</div>
	);
};

export default OwnerPaymentHistory;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SalesPrivateRoute = (props) => {
	const navigate = useNavigate();
	const { Component } = props;
	useEffect(() => {
		const salesToken = localStorage.getItem('salesToken');
		if (!salesToken) {
			// Token is not available, navigate to user login page
			navigate('/salesLogin');
		}
	}, [navigate]);

	return <Component />;
};

export default SalesPrivateRoute;

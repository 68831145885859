import React, { useEffect, useState } from 'react';
import WishlistedRoomCard from '../../components/StudentSide/WIshlistedRoomCard';
import Wrapper from '../../components/Wrapper';
import { PiSmileySadThin } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import Loader, { SkeletonLoader } from '../../components/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWishlistedData, removeWishlistRoom } from '../../redux-store/slice/wishlistSlice';
import ScheduleVisitPopup from '../../components/StudentSide/ScheduleVisitPopup';
import toast from 'react-hot-toast';
import RoomCard from '../../components/RoomDetails/RoomCard';

const WishlistedRoomList = () => {
	const dispatch = useDispatch();
	const { wishlistedPgs, isLoading, isError } = useSelector((state) => state.wishlist);
	const [removeLoading, setRemoveLoading] = useState(false);

	useEffect(() => {
		dispatch(fetchWishlistedData());
	}, [dispatch]);

	const handleRemoveFromWishlist = async (room) => {
		try {
			setRemoveLoading(true);
			const data = await dispatch(removeWishlistRoom(room.entery_id));
			if (data) {
				toast.success('PG removed from shortlist');
			}
			setRemoveLoading(false);
		} catch (error) {
			console.error(error);
			setRemoveLoading(false);
		}
	};

	return (
		<div className='my-28'>
			<Wrapper>
				<ScheduleVisitPopup />
				<h1 className='text-center font-semibold text-gray-800 text-xl'>Your Wishlisted Accommodations</h1>
				{isError ? (
					<div className='text-center mt-6 text-red-500'>Network Error, Please try again</div>
				) : (
					<div className='flex flex-col'>
						{isLoading && (
							<>
								<SkeletonLoader />
								<SkeletonLoader />
								<SkeletonLoader />
							</>
						)}
						{wishlistedPgs?.length > 0 ? (
							<div className='flex flex-col mx-4'>
								{wishlistedPgs?.map((room) => (
									<RoomCard
										key={room.roomid}
										room={room}
										handler={handleRemoveFromWishlist}
										isLoading={removeLoading}
										button='Remove'
									/>
								))}
							</div>
						) : (
							<div className={`w-full flex flex-col items-center justify-center mt-24 px-12 gap-8 ${isLoading ? 'hidden' : ''}`}>
								<PiSmileySadThin
									size={68}
									color='gray'
								/>
								<div className='flex flex-col items-center gap-1'>
									<p className='text-gray-500 font-medium text-center'> You don't have Wishlisted Accommodations</p>{' '}
									<Link
										to='/'
										className='border-b-2 border-red-500 flex items-center gap-2'
									>
										Wishlist Now
										<FaArrowRight
											color='red'
											size={14}
										/>
									</Link>
								</div>
							</div>
						)}
					</div>
				)}
			</Wrapper>
		</div>
	);
};

export default WishlistedRoomList;

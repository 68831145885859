import React from 'react';
import image from '../assets/images/404.avif';
import Wrapper from '../components/Wrapper';

const NotFound = () => {
	return (
		<div className='min-h-screen  flex flex-col items-center justify-center my-24'>
			<Wrapper>
				<div className='max-w-md text-center'>
					<h1 className='text-4xl font-bold text-gray-800 mb-4'>404 - Page Not Found</h1>
					<p className='text-lg text-gray-600 mb-8'>Sorry, the page you are looking for does not exist.</p>
					<img
						src={image}
						alt='404'
						className='w-full max-w-sm mx-auto'
					/>
					<p className='text-gray-600 mt-4'>The page you are looking for might have been removed or its name changed or is temporarily unavailable.</p>
				</div>
			</Wrapper>
		</div>
	);
};

export default NotFound;

import React from 'react';
import Wrapper from '../../components/Wrapper';

const TermsAndConditions = () => {
	return (
		<Wrapper>
			<div className='min-h-screen flex flex-col items-center justify-center bg-gray-100 my-24'>
				<div className='max-w-3xl bg-white p-8 rounded-lg shadow-lg'>
					<h1 className='text-3xl font-semibold text-gray-800 mb-6'>Terms and Conditions</h1>
					<ol className='text-gray-700 space-y-1'>
						<li>1. The provided phone number must be valid and active.</li>
						<li>2. The applicant should be residing in the same PG premises. Verification will be conducted via a call with the owner.</li>
						<li>3. At least one room in the PG should be available at the time of verification to qualify for cashback.</li>
						<li>
							4. Upon filling the form and verifying that there are no vacant rooms in your PG, a minimum cashback of Rs. 20 will be awarded if
							the verification matches.
						</li>
						<li>
							5. A qualified lead refers to an individual residing in the same PG, whose owner has consented to list their property on the
							PgVala platform. Cashback amounts range from Rs. 100 to up to Rs. 500 for such leads.
						</li>
					</ol>
				</div>
				<div className='max-w-3xl bg-white p-8 rounded-lg shadow-lg'>
					<h1 className='text-3xl font-semibold text-gray-800 mb-6'>Process</h1>
					<ol className='text-gray-700 space-y-1'>
						<li>1. Initial verification of identity will be conducted.</li>
						<li>2. Qualification as a lead will be determined.</li>
						<li>3.Sharing of our content on social media platforms is required.</li>
						<li>4. Our team will initiate contact for your UPI ID within 2-3 hours.</li>
						<li>5. Cashback will be released into your account upon verification and completion of the process.</li>
					</ol>
				</div>
			</div>
		</Wrapper>
	);
};

export default TermsAndConditions;

import React, { useState } from 'react';

const ReviewForm = () => {
	const [formData, setFormData] = useState({
		name: '',
		college: '',
		duration: '',
		pgReview: '',
		ownerReview: '',
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(formData);
	};

	return (
		<div className='min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12 my-20'>
			<div className='relative py-3 sm:w-[600px] sm:mx-auto'>
				<div className='relative px-4 py-10 bg-white border mx-8 md:mx-0 shadow rounded-3xl sm:p-10'>
					<h1 className='mb-8 font-bold text-2xl text-gray-700 text-center'>Pg Review</h1>
					<div className='max-w-md mx-auto'>
						<form onSubmit={handleSubmit}>
							<div className='input-field'>
								<input
									type='text'
									name='name'
									value={formData.name}
									onChange={handleChange}
									placeholder='Your Name'
									className='block w-full px-4 py-3 placeholder-gray-400 text-gray-900 rounded-md border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-transparent'
								/>
							</div>
							<div className='input-field mt-5'>
								<input
									type='text'
									name='college'
									value={formData.college}
									onChange={handleChange}
									placeholder='Your College/Coaching Center'
									className='block w-full px-4 py-3 placeholder-gray-400 text-gray-900 rounded-md border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-transparent'
								/>
							</div>
							<div className='input-field mt-5'>
								<input
									type='text'
									name='duration'
									value={formData.duration}
									onChange={handleChange}
									placeholder='Since when are you in PG'
									className='block w-full px-4 py-3 placeholder-gray-400 text-gray-900 rounded-md border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-transparent'
								/>
							</div>
							<div className='input-field mt-5'>
								<textarea
									name='pgReview'
									value={formData.pgReview}
									onChange={handleChange}
									placeholder='PG Review'
									rows='4'
									className='block w-full px-4 py-3 placeholder-gray-400 text-gray-900 rounded-md border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-transparent'
								></textarea>
							</div>
							<div className='input-field mt-5'>
								<textarea
									name='ownerReview'
									value={formData.ownerReview}
									onChange={handleChange}
									placeholder='Owner Review'
									rows='4'
									className='block w-full px-4 py-3 placeholder-gray-400 text-gray-900 rounded-md border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-transparent'
								></textarea>
							</div>
							<div className='mt-7'>
								<button
									type='submit'
									className='text-white w-full py-3 rounded-lg bg-red-500 hover:bg-red-600 focus:outline-none'
								>
									Submit Review
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReviewForm;

import React from 'react';
import { IoPerson } from 'react-icons/io5';
import { FaCalendar } from 'react-icons/fa';
import { MdPlace, MdHouse } from 'react-icons/md';
import { Link } from 'react-router-dom';

const StudentBookingCard = ({ booking }) => {
	return (
		<div className='w-full max-w-md mx-auto bg-gray-100 shadow-md rounded-md overflow-hidden'>
			<Link
				to={`/pgDetails/${booking.accid}`}
				className='block bg-red-500 text-white p-4'
			>
				<div className='flex items-center'>
					<MdHouse
						size={18}
						color='white'
					/>
					<span className='ml-2 font-semibold'>{booking.apartment_name}</span>
				</div>
			</Link>
			<div className='p-4'>
				<div className='flex items-center mb-2'>
					<IoPerson
						size={16}
						color='red'
					/>
					<span className='ml-2 font-semibold'>Owner Name:</span>
					<span className='ml-1'>{booking.owner_name}</span>
				</div>
				<div className='flex items-center mb-2'>
					<FaCalendar
						size={16}
						color='red'
					/>
					<span className='ml-2 font-semibold'>Date of Visit:</span>
					<span className='ml-1'>{booking.date}</span>
				</div>
				<div className='flex items-center'>
					<MdPlace
						size={18}
						color='red'
					/>
					<span className='ml-2 font-semibold'>Locality:</span>
					<span className='ml-1'>{booking.locality}</span>
				</div>
			</div>
		</div>
	);
};

export default StudentBookingCard;

import React, { useEffect, useState } from 'react';
import Wrapper from '../Wrapper';
import { IoPerson } from 'react-icons/io5';
import { FaCalendar, FaSpinner } from 'react-icons/fa';
import { IoIosTime } from 'react-icons/io';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAgentsData } from '../../redux-store/slice/agentSlice';
import { CancelVisitSchedule } from '../../api/student/student';
import toast from 'react-hot-toast';

const AgentSlider = () => {
	const dispatch = useDispatch();
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState({});

	const { agents } = useSelector((state) => state.agent);
	const reversedAgentsArr = agents && [...agents].reverse();

	useEffect(() => {
		dispatch(fetchAgentsData());
	}, [dispatch]);

	const handleCancelVisit = async (orderno) => {
		setIsError(false);
		setIsLoading((prevLoadingStates) => ({ ...prevLoadingStates, [orderno]: true }));
		try {
			const data = await CancelVisitSchedule(orderno);
			toast.success(data.status);
			if (data) {
				dispatch(fetchAgentsData);
			}
			setIsLoading((prevLoadingStates) => ({ ...prevLoadingStates, [orderno]: false }));
		} catch (error) {
			console.error(error);
			setIsError(true);
			setIsLoading((prevLoadingStates) => ({ ...prevLoadingStates, [orderno]: false }));
			toast.error('Some error occured');
		}
	};

	return (
		<Wrapper>
			<div className={`mt-8 md:mt-16 px-3 py-2 ${agents?.length > 0 ? '' : 'hidden'}`}>
				<div className='flex flex-col text-center w-full mb-4'>
					<h1 className='sm:text-2xl text-xl font-semibold title-font text-gray-700'>Visit Details</h1>
				</div>
				<div className='flex overflow-x-auto pb-3 gap-4 md:gap-8'>
					{reversedAgentsArr?.map((agent) => (
						<div
							className='whitespace-nowrap w-full flex flex-col gap-2 justify-center bg-gray-100 rounded-md px-8 py-2 bg-opacity-50 border border-gray-300'
							key={agent.entery_id}
						>
							<div className='flex items-center justify-center text-center gap-3  my-2'>
								<span className='font-medium text-sm text-gray-900 md:text-base'> Order No. - </span>
								<span className='text-sm font-semibold text-gray-500 md:text-base'>{agent.orderno}</span>
							</div>
							<div className='flex items-center gap-6'>
								<IoPerson color='red' />
								<span className='font-medium text-sm text-gray-600 md:text-base'> Agent Name : </span>
								<span className='text-sm font-semibold text-gray-900 md:text-base'>{agent.agent_name}</span>
							</div>
							<div className='flex items-center gap-6'>
								<FaCalendar color='red' />
								<span className='font-medium text-sm text-gray-600 md:text-base'>Visiting Date : </span>
								<span className='text-sm font-semibold text-gray-900 md:text-base'>
									{moment(agent.vist_date, 'YYYY-MM-DD').format('DD MMM')}
								</span>
							</div>
							<div className='flex items-center gap-6'>
								<IoIosTime color='red' />
								<span className='font-medium text-sm text-gray-600 md:text-base'>Visiting time :</span>
								<span className='text-sm font-semibold text-gray-900 md:text-base'>
									{moment(agent.start_time, 'HH:mm:ss').format('h:mm A')}
								</span>
							</div>
							<div className='flex justify-between text-xs my-3'>
								<button
									className={`flex justify-center border text-gray-500 border-red-500  py-0.5  items-center gap-2 px-2 text-sm focus:outline-none hover:bg-gray-200 rounded-full  whitespace-nowrap transform transition-transform  active:scale-90 ${
										isLoading[agent.orderno] ? 'cursor-not-allowed' : ''
									}`}
									onClick={() => handleCancelVisit(agent.orderno)}
									disabled={isLoading[agent.orderno]}
								>
									Cancel {isLoading[agent.orderno] && <FaSpinner className='animate-spin text-red-500' />}
								</button>

								<Link
									className='bg-red-500  text-white rounded-full flex items-center justify-center px-2'
									to={`/studentBookings/${agent.orderno}`}
								>
									<span>View Details</span>
								</Link>
							</div>
						</div>
					))}
				</div>
			</div>
		</Wrapper>
	);
};

export default AgentSlider;

import React from 'react';
import MainCarousel from '../components/LandingPage/MainCarousel';
import Search from '../components/LandingPage/Search';
import StatsSection from '../components/LandingPage/StatsSection';
import OnBoard from '../components/LandingPage/OnBoard';
import InstallButton from '../components/LandingPage/InstallButton';
import ReviewsSlider from '../components/LandingPage/ReviewsSlider';
import AgentSlider from '../components/LandingPage/AgentSlider';
import ScheduleVisitPopup from '../components/StudentSide/ScheduleVisitPopup';

const MainLandingPage = () => {
	return (
		<div className='my-20'>
			<MainCarousel />
			<Search />
			<StatsSection />
			<AgentSlider />
			<ReviewsSlider />
			<OnBoard />
			<InstallButton />
			<ScheduleVisitPopup />
		</div>
	);
};

export default MainLandingPage;

import React, { useEffect, useState } from 'react';
import Wrapper from '../../components/Wrapper';
import AgentOrderDetailsCard from '../../components/AgentSide/AgentOrderDetailsCard';
import Loader from '../../components/Loader/Loader';
import { useParams } from 'react-router-dom';
import { FetchOrderDetails } from '../../api/agent/agent';

const AgentOrderDetails = () => {
	const [orderDetails, setOrderDetials] = useState([]);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { orderid } = useParams();

	useEffect(() => {
		setIsError(false);
		setIsLoading(true);
		const fetchData = async () => {
			try {
				const data = await FetchOrderDetails(orderid);
				setOrderDetials(data);
				setIsLoading(false);
			} catch (error) {
				console.error(error);
				setIsError(true);
				setIsLoading(false);
			}
		};
		fetchData();
	}, []);

	return (
		<div className='my-24 mx-4'>
			<Wrapper>
				<h1 className='text-center font-semibold text-gray-800 text-xl mb-8'> Order Details</h1>
				{isError ? (
					<div className='text-center mt-6 text-red-500'>Network Error, Please try again</div>
				) : (
					<div className='flex flex-col'>
						{isLoading && (
							<div className='flex justify-center'>
								<Loader />{' '}
							</div>
						)}
						{orderDetails?.map((order) => (
							<AgentOrderDetailsCard
								order={order}
								key={order.entery_id}
								orderid={orderid}
							/>
						))}
					</div>
				)}
			</Wrapper>
		</div>
	);
};

export default AgentOrderDetails;

import React, { useState } from 'react';
import ScratchCard from 'react-scratchcard';
import image from '../../assets/scratch.jpg';

const ScratchCards = () => {
	const [scratchedText, setScratchedText] = useState('Congratulations! You WON!');

	const handleScratchComplete = () => {
		console.log('The card is now clear!');
		setScratchedText('Congratulations! You WON!');
	};

	const scratchCardsData = [
		{ id: 1, text: 'Card 1' },
		{ id: 2, text: 'Card 2' },
		{ id: 3, text: 'Card 3' },
		// Add more scratch card data as needed
	];

	return (
		<div className=' min-h-screen my-24'>
			<div className='max-w-4xl mx-auto'>
				<h1 className='text-2xl font-bold text-center mb-8'>Your Scratch Cards</h1>
				<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
					{scratchCardsData.map((card) => (
						<div
							key={card.id}
							className='bg-white rounded-lg  overflow-hidden mx-auto cursor-pointer'
						>
							<ScratchCard
								width={268}
								height={268}
								image={image}
								finishPercent={50}
								onComplete={handleScratchComplete}
							>
								<div className='p-4'>{scratchedText}</div>
							</ScratchCard>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default ScratchCards;

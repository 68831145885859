import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// import { FetchCityList } from '../../api/app/app';
// import Loader from '../Loader/Loader';

const Search = () => {
	const [selectedCity, setSelectedCity] = useState('');
	const [showDropdown, setShowDropdown] = useState(false);
	// const [isLoading, setIsLoading] = useState(false);
	// const [isError, setIsError] = useState(false);

	const cityNames = ['Bhilai', 'Raipur'];
	const dropdownRef = useRef(null);

	useEffect(() => {
		// const fetchCityList = async () => {
		// 	setIsError(false);
		// 	try {
		// 		setIsLoading(true);
		// 		const data = await FetchCityList();
		// 		setCityNames(data);
		// 		setIsLoading(false);
		// 	} catch (error) {
		// 		console.error('Failed to get city list' + error);
		// 		setIsError(true);
		// 		setIsLoading(false);
		// 	}
		// };
		// fetchCityList();
	}, []);

	const navigate = useNavigate();

	const handleCityClick = (city) => {
		// Set the selected city and hide the dropdown
		setSelectedCity(city);
		setShowDropdown(false);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (selectedCity) {
			navigate(`/roomlist/${selectedCity}`);
			// Perform any additional actions here
		} else {
			console.log('No city selected');
		}
	};
	const handleInputChange = (event) => {
		const value = event.target.value;
		setSelectedCity(value);
		// Optionally, you can perform additional actions with the input value
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setShowDropdown(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	return (
		<div className='my-14 md:my- w-full z-30'>
			<form
				action=''
				className='flex justify-center items-center gap-2'
				onSubmit={handleSubmit}
			>
				<div
					className='relative'
					ref={dropdownRef}
				>
					<input
						type='text'
						placeholder='City'
						className='border border-gray-400 w-48 px-1  py-1'
						value={selectedCity}
						onChange={(event) => handleInputChange(event)}
						onFocus={() => setShowDropdown(true)}
					/>

					{showDropdown && (
						<div className='absolute border border-t-0 shadow-sm w-full'>
							<div className='flex flex-col w-full justify-center text-gray-500'>
								{cityNames.map((city, index) => (
									<span
										key={index}
										className='border-b py-1 hover:bg-gray-100 px-3 cursor-pointer bg-white'
										onClick={() => handleCityClick(city)}
									>
										{city}
									</span>
								))}
							</div>
						</div>
					)}
				</div>
				<button
					className={`bg-red-500 hover:bg-red-600 h-8 text-white px-4 flex justify-center items-center ${!selectedCity ? 'cursor-default' : ''}`}
					type='submit'
					disabled={selectedCity.trim('') === ''}
				>
					Search
				</button>
			</form>
		</div>
	);
};

export default Search;

import React, { useEffect, useState } from 'react';
import Wrapper from '../../components/Wrapper';
import { MdDoubleArrow } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { RegisterUser } from '../../api/auth/register';
import Step from '../../components/register/RegisterStep'; // Import the Step component
import { FaWhatsapp } from 'react-icons/fa';
import toast from 'react-hot-toast';

const Register = () => {
	const [currentStep, setCurrentStep] = useState(1);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [isLinkSent, setIsLinkSent] = useState(false);

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const referalCode = searchParams.get('referalCode');

	const cityName = sessionStorage.getItem('cityName');

	const [formData, setFormData] = useState({
		name: '',
		contact: '',
		city: '',
		working_place: '',
	});

	useEffect(() => {
		if (referalCode) {
			setFormData((prevFormData) => ({
				...prevFormData,
				referal_code: referalCode,
			}));
		}
	}, [referalCode]);

	useEffect(() => {
		if (cityName) {
			setFormData((prevFormData) => ({
				...prevFormData,
				searched_city: cityName,
			}));
		}
	}, [cityName]);

	const handleInputChange = (e) => {
		const { name, value } = e.target;

		setFormData((prevFormData) => {
			if (name === 'contact' && /^\d+$/.test(value)) {
				const newValue = value.length <= 10 && !value.startsWith('91') ? `91${value}` : value;

				return {
					...prevFormData,
					[name]: newValue,
				};
			} else {
				return {
					...prevFormData,
					[name]: value,
				};
			}
		});
	};

	const handleNextStep = () => {
		if (currentStep < steps.length) {
			setCurrentStep((prevStep) => prevStep + 1);
		}
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		setIsError(false);

		const isAnyFieldBlank = Object.values(formData).some((value) => value.trim() === '');
		const isPhoneNumberValid = formData.contact.trim().length === 12;

		if (isAnyFieldBlank || !isPhoneNumberValid) {
			setIsError(true);
			return toast.error('Please fill all the fields');
		}

		if (currentStep < steps.length) {
			handleNextStep();
		} else {
			setIsLoading(true);

			try {
				const data = await RegisterUser(formData);
				console.log(data);

				setIsLoading(false);
				if (data) {
					toast.success(data.status);
					setIsLinkSent(true);
				}
			} catch (error) {
				console.error('Error in registering user ' + error);
				setIsError(true);
				toast.error('Some error occured');
				setIsLoading(false);
			}
			setCurrentStep(1);
		}
	};

	const steps = [
		{ label: 'Tell us your name', name: 'name', placeholder: 'Name' },
		{ label: 'Your Contact No.', name: 'contact', placeholder: 'Contact no.' },
		{ label: 'From which city you are', name: 'city', placeholder: 'Enter Your City' },
		{ label: 'Your Workplace', name: 'working_place', placeholder: 'College/Coaching/Company Name' },
	];

	const CurrentStep = steps[currentStep - 1];

	const openWhatsApp = () => {
		// Use the whatsapp:// URL scheme
		const whatsappUrl = 'whatsapp://';
		// Try to open the WhatsApp app
		window.location.href = whatsappUrl;
	};

	return (
		<div className='my-24 mx-2'>
			<Wrapper>
				<h1 className='font-semibold text-center text-gray-700 text-xl'>Register Yourself</h1>
				{isLinkSent ? (
					<div className=' mt-14 flex flex-col gap-3 items-center'>
						<h3 className='text-lg font-medium text-gray-800'>Link sent on Whatsapp</h3>
						<button
							className='flex items-center text-medium gap-2 bg-green-500 text-white font-mediem rounded-full justify-center py-1.5 px-3'
							onClick={openWhatsApp}
						>
							<FaWhatsapp size={24} />
							Open Whatsapp
						</button>
					</div>
				) : (
					<div className='mt-20'>
						<form
							onSubmit={handleFormSubmit}
							className='flex flex-col mx-4'
						>
							<div className='transition-all duration-300 ease-in-out'>
								<Step
									formData={formData}
									handleInputChange={handleInputChange}
									label={CurrentStep.label}
									name={CurrentStep.name}
									placeholder={CurrentStep.placeholder}
									isError={isError}
								/>
							</div>
							<div className='w-full flex justify-end '>
								{isLoading ? (
									<button className=' bg-red-500 mt-4 text-white py-1 px-4  hover:bg-red-600 flex items-center gap-2 cursor-not-allowed'>
										Registering...
									</button>
								) : currentStep < steps.length ? (
									<button
										type='submit'
										className=' bg-red-500 mt-4 text-white py-1 px-4  hover:bg-red-600 flex items-center gap-2'
										onClick={handleNextStep}
									>
										Next
										<MdDoubleArrow size={16} />
									</button>
								) : (
									<button
										type='submit'
										className=' bg-red-500 mt-4 text-white py-1 px-4  hover:bg-red-600 flex items-center gap-2'
										onClick={handleFormSubmit}
									>
										Register
										<MdDoubleArrow size={16} />
									</button>
								)}
							</div>
						</form>
						<div className='text-center mt-40 text-sm'>
							Already Registered?{' '}
							<Link
								className='text-red-500 border-b border-b-red-500'
								to='/userLogin'
							>
								Login here
							</Link>
						</div>
					</div>
				)}
			</Wrapper>
		</div>
	);
};

export default Register;

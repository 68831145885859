import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { SkeletonLoader } from '../components/Loader/Loader';
import FilterSelect from '../components/RoomDetails/FilterSelect';
import RoomCard from '../components/RoomDetails/RoomCard';
import ScheduleVisitPopup from '../components/StudentSide/ScheduleVisitPopup';
import Wrapper from '../components/Wrapper';
import { fetchPgsByCityname } from '../redux-store/slice/pgSlice';
import { addToWishlist } from '../redux-store/slice/wishlistSlice';

const RoomList = () => {
	const { cityname } = useParams();

	const [isAdding, setIsAdding] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { pgs, isLoading, isError } = useSelector((state) => state.fetchPgs);

	useEffect(() => {
		dispatch(fetchPgsByCityname(cityname));
	}, [cityname, dispatch]);

	const [categoryFilter, setCategoryFilter] = useState('');
	const [tenantFilter, setTenantFilter] = useState('');
	const [washroomFilter, setWashroomFilter] = useState('');
	const [localityFilter, setLocalityFilter] = useState('');
	const [priceFilter, setPriceFilter] = useState('');

	const filteredRoomData = pgs.filter((room) => {
		const categoryMatch = categoryFilter === '' || room.category === categoryFilter;
		const tenantMatch = tenantFilter === '' || room.tenant === tenantFilter;
		const washroomMatch = washroomFilter === '' || room.washroom_status === washroomFilter;
		const localityMatch = localityFilter === '' || room.locality.toLowerCase() === localityFilter.toLowerCase();
		const priceMatch = priceFilter === '' || room.rent_price <= parseInt(priceFilter, 10);
		return categoryMatch && tenantMatch && washroomMatch && localityMatch && priceMatch;
	});

	//For Seo optimization
	const pgNames = pgs?.map((i) => i.apartment_name);

	const handleAddToWishlist = async (room) => {
		const token = localStorage.getItem('token');
		if (token) {
			setIsAdding(true);
			const postData = {
				accid: room.accid,
				roomid: room.roomid,
				wishlist_date: new Date().toISOString().split('T')[0],
			};

			try {
				const data = await dispatch(addToWishlist(postData));

				if (data) {
					toast.success(data?.payload?.message || 'Added to wishlist');
				}
				setIsAdding(false);
			} catch (error) {
				console.error(error);
				setIsAdding(false);
			}
		} else {
			toast.error('Please Login  to continue');
			sessionStorage.setItem('cityName', cityname);
			navigate('/register');
		}
	};

	return (
		<div className='my-24'>
			<Helmet>
				<title>PgVala</title>
				<meta
					name='keywords'
					content={pgNames}
				/>
			</Helmet>
			<Wrapper>
				<ScheduleVisitPopup />
				<h1 className='text-center font-semibold text-gray-700 md:text-3xl my-3 md:my-8 text-lg'>
					All PGs in <span className='text-red-500'>{cityname}</span>
				</h1>
				{/* <div className='mb-4 w-full text-center'>
					<Link to={`/roomlist/swipe/${cityname}`}>Show in swipeable</Link>
				</div> */}
				{isError && (
					<div className='flex flex-col items-center justify-center'>
						<div className='text-center mt-6 text-red-500 text-sm'>Check your internet connection and try again</div>
						<button
							className='bg-red-500 rounded-full text-sm px-2 py-1 text-white mt-4'
							onClick={() => window.location.reload()}
						>
							Retry
						</button>
					</div>
				)}
				{isLoading && (
					<>
						<SkeletonLoader />
						<SkeletonLoader />
						<SkeletonLoader />
					</>
				)}
				{!isError && !isLoading && (
					<>
						{/* FILTERS STARTS */}
						<div className='flex md:justify-center gap-3 md:gap-6 overflow-x-auto scroll-bar mx-2 md:mx-auto md:w-full'>
							<button
								className={`px-2 md:px-3 md:py-1.5 py-0.5 rounded-full text-xs md:text-sm border border-gray-500 ${
									categoryFilter === '' &&
									washroomFilter === '' &&
									tenantFilter === '' &&
									localityFilter === '' &&
									priceFilter === ''
										? 'bg-gray-300 text-black'
										: 'bg-white text-gray-700 '
								}`}
								onClick={() => {
									setCategoryFilter('');
									setTenantFilter('');
									setWashroomFilter('');
									setLocalityFilter('');
									setPriceFilter('');
								}}
							>
								All
							</button>
							<FilterSelect
								id='category'
								name='category'
								value={categoryFilter}
								options={Array.from(new Set(pgs.map((room) => room.category)))}
								onChange={setCategoryFilter}
								placeholder='Category'
							/>
							<FilterSelect
								id='tenant'
								name='tenant'
								value={tenantFilter}
								options={Array.from(new Set(pgs.map((room) => room.tenant)))}
								onChange={setTenantFilter}
								placeholder='Tenant'
							/>
							<FilterSelect
								id='washroom'
								name='washroom'
								value={washroomFilter}
								options={Array.from(new Set(pgs.map((room) => room.washroom_status)))}
								onChange={setWashroomFilter}
								placeholder='Washroom Status'
							/>
							<FilterSelect
								id='locality'
								name='locality'
								value={localityFilter}
								options={Array.from(new Set(pgs.map((room) => room.locality)))}
								onChange={setLocalityFilter}
								placeholder='Locality'
							/>
							<input
								type='number'
								placeholder='Max Price'
								className={`px-2 py-1 rounded-full text-xs border border-gray-500 text-gray-800`}
								value={priceFilter}
								onChange={(e) => setPriceFilter(e.target.value)}
							/>
						</div>
						{/* FILTERS ENDS */}
						{filteredRoomData.length > 0 ? (
							<div className='flex flex-col mx-4'>
								{filteredRoomData.map((room) => (
									<RoomCard
										key={room.roomid}
										room={room}
										cityname={cityname}
										handler={handleAddToWishlist}
										isLoading={isAdding}
										button='Shortlist'
									/>
								))}
							</div>
						) : (
							<div className='w-full flex flex-col items-center justify-center mt-24 px-12 gap-8'>
								{pgs?.length === 0 && !isLoading && !isError ? (
									<div className='flex flex-col items-center gap-1'>
										<p className='text-gray-500 font-medium text-center'>Coming Soon in {cityname}</p>
									</div>
								) : (
									!isLoading &&
									!isError && <p className='text-gray-500  font-medium text-center'>No PGs found based on selected filters.</p>
								)}
							</div>
						)}
					</>
				)}
			</Wrapper>
		</div>
	);
};

export default RoomList;

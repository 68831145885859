import axios from 'axios';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { FaSpinner } from 'react-icons/fa6';
import { IoMdImages } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { GetRoomId, RegisterRoom } from '../../api/onboard/registerAccomodation';
import Wrapper from '../../components/Wrapper';

const AddRoom = () => {
	const navigate = useNavigate();
	const accidFromLocal = localStorage.getItem('accidLocal');
	const [images, setImages] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isImageUploading, setIsImageUploading] = useState(false);

	const [isError, setIsError] = useState(false);
	const [selectedPerks, setSelectedPerks] = useState([]);
	const [customPerk, setCustomPerk] = useState(''); // State to track the custom perk input
	const [customPerks, setCustomPerks] = useState([]);

	const [roomDetail, setRoomDetail] = useState({
		accid: accidFromLocal,
		roomid: '',
		washroom_status: '',
		status: '',
		perks: '',
		accomotation_type: '',
		rent_price: 0,
		category: '',
		rate: '',
		tenant: '',
		security_deposit: 0,
		images: {},
	});

	useEffect(() => {
		const getRoomId = async () => {
			try {
				const res = await GetRoomId(accidFromLocal);
				const roomIdFromApi = res.status;
				setRoomDetail((prevRoomDetail) => ({
					...prevRoomDetail,
					roomid: roomIdFromApi,
				}));
			} catch (error) {
				console.error(error);
			}
		};
		getRoomId();
	}, [accidFromLocal]);

	const togglePerk = (perk) => {
		setSelectedPerks((prevPerks) => {
			const isSelected = prevPerks.includes(perk);

			if (!isSelected) {
				return [...prevPerks, perk];
			} else {
				return prevPerks.filter((selectedPerk) => selectedPerk !== perk);
			}
		});

		setRoomDetail((prev) => ({
			...prev,
			perks: selectedPerks.join(', '), // Use the latest state
		}));
	};
	const addCustomPerk = () => {
		if (customPerk.trim() !== '') {
			// Check if the custom perk already exists in the selected perks
			if (!selectedPerks.includes(customPerk)) {
				setCustomPerks((prevCustomPerks) => [...prevCustomPerks, customPerk]);
				setSelectedPerks((prevPerks) => [...prevPerks, customPerk]); // Select the custom perk
				setRoomDetail((prev) => ({
					...prev,
					perks: [...selectedPerks, customPerk].join(', '),
				}));
				setCustomPerk(''); // Clear the custom perk input after adding
			} else {
				toast.error(`${customPerk} already added`);
				console.warn('Custom perk already exists:', customPerk);
			}
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setRoomDetail((prevroomDetail) => ({
			...prevroomDetail,
			[name]: value,
		}));
	};

	const handleRegister = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		try {
			for (const key in roomDetail) {
				if (roomDetail[key] === null || roomDetail[key] === undefined) {
					toast.error(`${key} is required`);
					setIsLoading(false);
					return;
				}
			}
			// Check if images object is not empty
			if (Object.keys(roomDetail.images).length === 0) {
				toast.error('Please upload image first');
				setIsLoading(false);
				return;
			}
			const res = await RegisterRoom(roomDetail);
			console.log(res);

			if (res) {
				toast.success('Room added successfully');
				setIsLoading(false);
				setTimeout(() => {
					navigate(`/success/${res?.roomid}`);
				}, 2000);
			}
		} catch (error) {
			console.error('Error in registering room : ' + error);
			setIsLoading(false);
			setIsError(true);
			toast.error('Error! Please try again.');
		}
	};

	const perksList = [
		'Bed',
		'Cupboard',
		'study table',
		'chair',
		'cooler',
		'ac',
		'CCTV camera',
		'security guard',
		'parking',
		'kitchen top',
		'Water purifier',
		'Food',
		'laundry',
		'Daily Cleaning',
		'Refrigerator',
		'Washing Machine',
	];

	const saveimage = async () => {
		if (!images || images.length === 0) {
			toast.error('Please select image first');
			return;
		}
		try {
			setIsImageUploading(true);
			const newImageUrls = [];
			for (const image of images) {
				const data = new FormData();
				data.append('file', image);
				data.append('upload_preset', 'jjflez2c');
				data.append('cloud_name', 'du5mxasbv');
				try {
					const response = await axios.post('https://api.cloudinary.com/v1_1/du5mxasbv/image/upload', data, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					});
					const url = response.data.secure_url;

					const prefix = 'https://res.cloudinary.com/du5mxasbv/image/upload/';
					const prefixLength = prefix.length;
					if (url.startsWith(prefix)) {
						const extractedString = url.slice(prefixLength);
						newImageUrls.push(extractedString);
					}
				} catch (uploadError) {
					console.error('Error uploading image:', uploadError);
				}
			}
			setRoomDetail((prevRoomDetail) => ({
				...prevRoomDetail,
				images: newImageUrls.reduce((acc, url, index) => {
					acc[`${prevRoomDetail.accid}_cldnry_${index + 1}`] = url;
					return acc;
				}, {}),
			}));
			setIsImageUploading(false);
			toast.success(`${images?.length} image uploaded`);
		} catch (error) {
			console.error(error);
			toast.error('Error in uploading image Try again');
			setIsImageUploading(false);
		}
	};

	const handleImageCancel = (index) => {
		setImages((previmage) => {
			const updatedimage = [...previmage];
			updatedimage.splice(index, 1);
			return updatedimage;
		});
	};

	const perksListWithCustom = [...perksList, ...customPerks];
	return (
		<div className='my-24'>
			<Wrapper>
				{isLoading && <div className='fixed inset-0 bg-black opacity-20 z-50'></div>}
				<h1 className='text-center font-semibold text-gray-800 text-xl'>Register Room</h1>
				<form
					className='mx-4'
					onSubmit={handleRegister}
				>
					<div className='border-b border-gray-900/10 pb-12'>
						<div className='mt-10 flex flex-col gap-6'>
							<div>
								<label
									htmlFor='status'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Status
								</label>
								<div className='mt-2'>
									<select
										type='text'
										name='status'
										id='status'
										className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 text-sm'
										value={roomDetail.status}
										onChange={handleInputChange}
										required
									>
										<option value=''>Select Status</option>
										<option value='available'>Available</option>
										<option value='booked'>Booked</option>
									</select>
								</div>
							</div>
							<div>
								<label
									htmlFor='washroom_status'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Washroom status
								</label>
								<div className='mt-2'>
									<select
										id='washroom_status'
										name='washroom_status'
										autoComplete='washroom_status'
										className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 text-sm'
										value={roomDetail.washroom_status}
										onChange={handleInputChange}
										required
									>
										<option value=''>Select washroom Status</option>
										<option value='attached'>Attacthed</option>
										<option value='common'>Common</option>
									</select>
								</div>
							</div>

							<div>
								<label
									htmlFor='perks'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Select Perks
								</label>
								<div className='mt-2 gap-3 flex flex-wrap'>
									{perksListWithCustom.map((perk) => (
										<button
											key={perk}
											type='button'
											className={`${
												selectedPerks.includes(perk) ? 'bg-gray-500 text-white' : ' text-gray-800'
											} px-2 py-0.5 text-[12px] rounded-full text-gray-500 flex items-center gap-1 bg-gray-300 focus:outline-none transform transition-transform  active:scale-90`}
											onClick={() => togglePerk(perk)}
										>
											{perk}
										</button>
									))}

									{/* Input field for custom perk */}
									<div className='flex items-center gap-2'>
										<input
											type='text'
											placeholder='Add custom perk'
											className='border border-gray-500 px-2 py-1 text-xs rounded-full'
											value={customPerk}
											onChange={(e) => setCustomPerk(e.target.value)}
										/>
										<button
											type='button'
											className='bg-red-600 text-white px-2 py-1 text-[12px] rounded-full transform transition-transform  active:scale-90'
											onClick={addCustomPerk}
										>
											Add
										</button>
									</div>
								</div>
							</div>
							<div>
								<label
									htmlFor='accomotation_type'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Accomodation Type
								</label>
								<div className='mt-2'>
									<select
										name='accomotation_type'
										id='accomotation_type'
										className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 text-sm'
										value={roomDetail.accomotation_type}
										onChange={handleInputChange}
										required
									>
										<option value=''>Select accomodation type</option>
										<option value='1bhk'>1 BHK</option>
										<option value='2bhk'>2 BHK</option>
										<option value='3bhk'>3 BHK</option>
										<option value='single-sharing'>Single Sharing</option>
										<option value='double-sharing'>Double Sharing</option>
										<option value='triple-sharing'>Triple Sharing</option>
									</select>
								</div>
							</div>
							<div>
								<label
									htmlFor='rent_price'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Rent Price
								</label>
								<div className='mt-2'>
									<input
										type='number'
										name='rent_price'
										id='rent_price'
										autoComplete='street-address'
										className='block w-full text-sm rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
										value={roomDetail.rent_price}
										onChange={handleInputChange}
										placeholder='eg.5000'
										required
									/>
								</div>
							</div>
							<div>
								<label
									htmlFor='rate'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Rate
								</label>
								<div className='mt-2'>
									<select
										type='text'
										name='rate'
										id='rate'
										autoComplete='address-level2'
										className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 text-sm'
										value={roomDetail.rate}
										onChange={handleInputChange}
										required
									>
										<option value=''>Select rate</option>
										<option value='monthly'>Monthly</option>
										<option value='monthlyBed'>Monthly Bed</option>
										<option value='yearly'>Yearly</option>
									</select>
								</div>
							</div>
							<div>
								<label
									htmlFor='security_deposit'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Security Deposit
								</label>
								<div className='mt-2'>
									<input
										type='number'
										name='security_deposit'
										id='security_deposit'
										className='block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 text-sm'
										placeholder='eg 2000'
										value={roomDetail.security_deposit}
										onChange={handleInputChange}
										required
									/>
								</div>
							</div>
							<div>
								<label
									htmlFor='category'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Furnishing Category
								</label>
								<div className='mt-2'>
									<select
										type='text'
										name='category'
										id='category'
										className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 text-sm'
										value={roomDetail.category}
										onChange={handleInputChange}
										required
									>
										<option value=''>Select Furnishing Category</option>
										<option value='furnished'>Furnished</option>
										<option value='semi-furnished'>Semi Furnished</option>
										<option value='non-furnished'>Non Furnished</option>
									</select>
								</div>
							</div>
							<div>
								<label
									htmlFor='tenant'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Tenant Type
								</label>
								<div className='mt-2'>
									<select
										type='text'
										name='tenant'
										id='tenant'
										className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 text-sm'
										value={roomDetail.tenant}
										onChange={handleInputChange}
										required
									>
										<option value=''>Select Tenant Type</option>
										<option value='boys'>Boys</option>
										<option value='girls'>Girls</option>
										<option value='family'>Family</option>
									</select>
								</div>
							</div>

							<div className='sm:col-span-3'>
								<label
									htmlFor='security_deposit'
									className='block text-sm font-medium leading-6 text-gray-900 mb-6'
								>
									Upload Images
								</label>
								<div className='mt-2 flex flex-col border border-gray-300 shadow-sm p-2 rounded'>
									<div className='flex items-center gap-8'>
										<label
											htmlFor='imageUrl'
											className='text-sm  px-2 py-1 rounded-md border border-red-500 cursor-pointer flex items-center gap-2'
										>
											Select
											<IoMdImages
												size={18}
												color='red'
											/>
										</label>
										<input
											type='file'
											name='imageUrl'
											id='imageUrl'
											className='text-white  hidden'
											onChange={(e) => setImages((prevImages) => [...prevImages, ...e.target.files])}
											accept='image/*'
											multiple
										/>
										{isImageUploading ? (
											<div className='text-sm bg-red-500 px-2 py-1 rounded-md text-white  flex items-center gap-2 cursor-not-allowed'>
												Uploading...
											</div>
										) : (
											<div
												className='text-sm bg-red-500 px-2 py-1 rounded-md text-white cursor-pointer flex items-center gap-2'
												onClick={saveimage}
											>
												Upload
												<FaCloudUploadAlt />
											</div>
										)}
									</div>
									{images && images.length > 0 && (
										<div className='flex flex-wrap mt-4 gap-2'>
											{images.map((file, index) => (
												<div
													key={index}
													className='relative'
												>
													<img
														src={URL.createObjectURL(file)}
														alt={`preview-${index}`}
														className='w-20 h-20 rounded'
													/>
													<span
														className='absolute top-1 right-1 bg-red-500 text-white w-4 text-xs text-center h-4 rounded-full cursor-pointer'
														onClick={() => handleImageCancel(index)}
													>
														X
													</span>
												</div>
											))}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>

					<div className='mt-6 flex items-center justify-end gap-x-6'>
						<button
							type='button'
							className='rounded-md  px-2 py-1 text-sm font-semibold leading-6 text-gray-900 border border-red-500'
							onClick={() => navigate(-1)}
						>
							Cancel
						</button>
						<button
							className={`flex justify-center text-white bg-red-500 border-0 py-1 md:py-2 items-center gap-2 px-4 text-sm focus:outline-none hover:bg-red-600 rounded-md leading-6 md:text-lg whitespace-nowrap transform transition-transform  active:scale-90 ${
								isLoading ? 'cursor-not-allowed' : ''
							}`}
							disabled={isLoading}
						>
							{isLoading ? (
								<>
									Saving <FaSpinner className='animate-spin text-white' />
								</>
							) : (
								'Save'
							)}
						</button>
					</div>
				</form>
			</Wrapper>
		</div>
	);
};

export default AddRoom;

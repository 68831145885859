import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { GiSofa } from 'react-icons/gi';
import { IoBed, IoClose } from 'react-icons/io5';
import { FaRegEdit, FaUsers } from 'react-icons/fa';
import { MdBathtub } from 'react-icons/md';
import { UpdateRoomDetails } from '../../api/owner/ownerdata';
import { fetchOwnerRoomList } from '../../redux-store/slice/ownerSlice';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';

const Accomodation = ({ room }) => {
	const dispatch = useDispatch();

	const imagesObject = JSON.parse(room.images);
	const imageArray = Object.values(imagesObject).filter((image) => !!image); // Filter out null or undefined images
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [rentPrice, setRentPrice] = useState(room.rent_price);
	const [tenantType, setTenantType] = useState(room.tenant);
	const [furnishedCategory, setFurnishedCategory] = useState(room.category);
	const [accomodationType, setAccomodationType] = useState(room.accomotation_type);

	const [updatingField, setUpdatingField] = useState(null);

	const [modalOpen, setModalOpen] = useState(false);

	const perksList = room.perks ? room.perks?.split(',') : ['Basic Perks'];

	const handleUpdate = async (e, field, value) => {
		e.preventDefault();
		setIsLoading(true);
		setIsError(false);
		setUpdatingField(field);
		try {
			const data = await UpdateRoomDetails(`room_detail_update/?roomid=${room.roomid}&field=${field}&value=${value}`);
			toast.success(data.status);
			dispatch(fetchOwnerRoomList());
			setIsLoading(false);
		} catch (error) {
			console.error(error);
			setIsError(true);
			toast.error(`Error in updating ${field}`);
			setIsLoading(false);
		}
		setIsLoading(false);
	};

	const handleStatusChange = (e) => {
		if (room.current_bed > 0) {
			handleUpdate(e, 'status');
			handleUpdate(e, 'status', room.status === 'Available' ? 'Booked' : 'Available');
		}
	};

	if (isError) {
		toast.error('An error occured');
	}

	const Button = ({ field }) => {
		return (
			<button
				className={`text-white px-2 rounded text-sm py-1 active:scale-90 duration-300 ${updatingField === field && isLoading ? 'bg-red-300' : 'bg-red-500'}`}
				type='submit'
				disabled={isLoading || updatingField === field}
			>
				Update
			</button>
		);
	};

	return (
		<>
			<div className='container px-5 py-8 mx-auto '>
				<div className=' mx-auto flex flex-col md:flex-row md:items-center'>
					<div className='lg:w-1/2 w-full lg:h-auto my-2'>
						<Carousel
							showThumbs={false}
							infiniteLoop={true}
							autoPlay={true}
							interval={1000}
							showStatus={false}
						>
							{imageArray?.map((imageUrl, index) => (
								<img
									key={index}
									alt={`Room ${index + 1}`}
									className='h-full w-full object-cover object-center rounded '
									src={`https://res.cloudinary.com/du5mxasbv/image/upload/${imageUrl}`}
								/>
							))}
						</Carousel>
					</div>
					<div className='lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0'>
						<div className='text-sm title-font text-gray-500 tracking-widest'>Room ID : {room.roomid}</div>
						<div className='flex flex-wrap gap-2 pb-1 my-2'>
							{perksList.map((perk) => (
								<span
									key={perk}
									className='bg-[#5F8670] text-white px-2 md:px-3 py-[2px] md:py-1 rounded-full text-xs md:text-sm flex items-center text-center'
								>
									{perk}
								</span>
							))}
						</div>
						<div className='grid gap-2 grid-cols-2 my-4 rounded-md'>
							<div className='flex gap-2 justify-center bg-gray-100 py-1 rounded-full  items-center  md:text-base'>
								<IoBed
									size={20}
									color='red'
								/>
								<span className='text-gray-500 text-sm text-center font-medium'>{room.accomotation_type}</span>
							</div>
							<div className='flex gap-2 justify-center bg-gray-100 py-1 rounded-full  items-center  md:text-base'>
								<GiSofa
									size={20}
									color='red'
								/>
								<span className='text-gray-500 text-sm text-center font-medium'>{room.category}</span>
							</div>
							<div className='flex gap-2 justify-center bg-gray-100 py-1 rounded-full  items-center  md:text-base'>
								<FaUsers
									size={20}
									color='red'
								/>
								<span className='text-gray-500 text-sm text-center font-medium'>{room.tenant}</span>
							</div>
							<div className='flex gap-2 justify-center bg-gray-100 py-1 rounded-full  items-center  md:text-base'>
								<MdBathtub
									size={20}
									color='red'
								/>
								<span className='text-gray-500 text-sm text-center font-medium'>{room.washroom_status}</span>
							</div>
						</div>
						<div className='bg-gray-100 p-2 rounded-md flex gap-2 flex-col'>
							<div className='font-medium text-sm text-gray-700'>Total Beds - {room.total_bed}</div>

							<div className='font-medium text-sm text-gray-700 relative flex'>
								<span>Current Beds - </span>
								<div className='flex border border-red-500 ml-2 rounded text-base bg-white'>
									<button
										className='px-3 active:scale-75 duration-300'
										onClick={(e) => handleUpdate(e, 'current_bed', -1)}
									>
										-
									</button>
									<button className='px-3 bg-red-500 text-white'>{room.current_bed}</button>
									<button
										className='px-3 active:scale-75 duration-300'
										onClick={(e) => handleUpdate(e, 'current_bed', 1)}
									>
										+
									</button>
								</div>
							</div>
							<div className='font-medium text-sm text-gray-700 gap-2 flex items-center'>
								<span className=''>Room Status : </span>
								<label className='flex items-center'>
									<input
										type='checkbox'
										className='hidden'
										checked={room.status === 'Available'}
										onChange={handleStatusChange}
									/>
									<div
										className={`relative w-12 h-6 bg-gray-300 rounded-full transition-all duration-300 cursor-pointer ${
											room.status === 'Available' ? 'bg-green-500' : 'bg-gray-300'
										}`}
									>
										<div
											className={`absolute left-2 top-1 w-4 h-4 bg-white rounded-full transition-all duration-300 ${
												room.status === 'Available' ? 'transform translate-x-full' : ''
											}`}
										></div>
									</div>
								</label>
								<span className={`ml-2 ${room.status === 'Available' ? 'text-green-500' : 'text-red-500'}`}>{room.status}</span>
							</div>
						</div>
						<div className='flex gap-2 justify-between mt-8 md:mt-10'>
							<div className='whitespace-nowrap'>
								<span className='font-semibold text-xl md:text-2xl text-gray-900'>&#8377;{room.rent_price}</span>
								<span className='text-sm text-gray-800'>/{room.rate}</span>
							</div>
							<button
								className='flex justify-center text-white bg-red-500 border-0 py-1 md:py-2 items-center gap-2 px-4 text-sm focus:outline-none hover:bg-red-600 rounded-full md:text-lg whitespace-nowrap'
								onClick={() => setModalOpen(true)}
							>
								<FaRegEdit />
								<span className='md:text-lg'>Edit</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			{/* UPDATE ROOM DETAILS MODAL */}
			<div className={`fixed z-50`}>
				<div
					className={`fixed inset-0 top-0  opacity-50 bg-black   ${modalOpen ? '' : 'hidden'}`}
					onClick={() => setModalOpen(false)}
				></div>
				<div
					className={`bg-white fixed bottom-0 w-full px-3 py-8 overflow-y-auto flex flex-col gap-3 items-center transition ease-in-out duration-500 h-[400px] rounded-t-lg 
                 ${modalOpen ? 'translate-y-0' : 'md:translate-x-full translate-y-full'}
                 sm:w-96 sm:right-0 sm:top-1/2 sm:-translate-y-1/2 sm:max-w-md sm:rounded-md md:h-full md:border border-gray-400 justify-center`}
				>
					<button
						className='absolute top-2 right-2'
						onClick={() => setModalOpen(false)}
					>
						<IoClose size={20} />
					</button>

					<div className='flex flex-col gap-4 items-center'>
						<div className='text-gray-800 text-sm border-gray-400'>Room id : {room.roomid}</div>
						<form
							action=''
							className='flex flex-col gap-1.5'
							onSubmit={(e) => handleUpdate(e, 'rent_price', rentPrice)}
						>
							<label
								htmlFor='accomodation_type'
								className='font-semibold text-gray-800'
							>
								Rent Price
							</label>
							<div className='flex gap-3'>
								<input
									type='number'
									placeholder='Enter New Price'
									className='border border-gray-400 rounded text-sm px-2 py-1 w-40'
									value={rentPrice}
									onChange={(e) => setRentPrice(e.target.value)}
								/>
								<Button field='rent_price' />
							</div>
						</form>

						<form
							action=''
							className='flex flex-col gap-1.5 px-6'
							onSubmit={(e) => handleUpdate(e, 'tenant', tenantType)}
						>
							<label
								htmlFor='tenant'
								className='font-semibold text-gray-900'
							>
								Tenant
							</label>
							<div className='flex gap-3'>
								<select
									name='tenant'
									id='tenant'
									onChange={(e) => setTenantType(e.target.value)}
									className='border border-gray-400 rounded text-sm px-2 py-1 w-40'
									value={tenantType}
								>
									<option value='boys'>Boys</option>
									<option value='girls'>Girls</option>
									<option value='family'>Family</option>
								</select>
								<Button field='tenant' />
							</div>
						</form>
						<form
							action=''
							className='flex flex-col gap-1.5 px-6'
							onSubmit={(e) => handleUpdate(e, 'category', furnishedCategory)}
						>
							<label
								htmlFor='category'
								className='font-semibold text-gray-800'
							>
								Category
							</label>
							<div className='flex gap-3'>
								<select
									name='category'
									id='category'
									onChange={(e) => setFurnishedCategory(e.target.value)}
									className='border border-gray-400 rounded text-sm px-2 py-1 w-40'
									value={furnishedCategory}
								>
									<option value='furnished'>Furnished</option>
									<option value='non-furnished'>Non Furnished</option>
									<option value='semi-furnished'>Semi Furnished</option>
								</select>
								<Button field='category' />
							</div>
						</form>
						<form
							action=''
							className='flex flex-col gap-1.5 px-6'
							onSubmit={(e) => handleUpdate(e, 'accomotation_type', accomodationType)}
						>
							<label
								htmlFor='accomotation_type'
								className='font-semibold text-gray-800'
							>
								Accomodation Type
							</label>
							<div className='flex gap-3'>
								<select
									name='accomotation_type'
									id='accomotation_type'
									onChange={(e) => setAccomodationType(e.target.value)}
									className='border border-gray-400 rounded text-sm px-2 py-1 w-40'
									value={accomodationType}
								>
									<option value='1bhk'>1 BHK</option>
									<option value='2bhk'>2 BHK</option>
									<option value='3bhk'>3 BHK</option>
									<option value='single-sharing'>Single Sharing</option>
									<option value='double-sharing'>Double Sharing</option>
									<option value='triple-sharing'>Triple Sharing</option>
								</select>

								<Button field='accomotation_type' />
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Accomodation;

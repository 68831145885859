import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FetchRoomsData } from '../../api/app/rooms';

export const fetchPgsByCityname = createAsyncThunk('fetchPgsByCityname', async (cityname) => {
	const data = await FetchRoomsData(cityname);
	return data;
});

const fetchPgSlice = createSlice({
	name: 'fetchPg',
	initialState: {
		isLoading: false,
		pgs: [],
		isError: false,
	},
	extraReducers: (builder) => {
		builder.addCase(fetchPgsByCityname.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(fetchPgsByCityname.fulfilled, (state, action) => {
			state.isLoading = false;
			state.pgs = action.payload;
		});
		builder.addCase(fetchPgsByCityname.rejected, (state, action) => {
			console.log('Error', action.payload);
			state.isError = true;
			state.isLoading = false;
		});
	},
});

export default fetchPgSlice.reducer;

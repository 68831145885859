import React, { useState } from 'react';
import { GiSofa } from 'react-icons/gi';
import { IoBed } from 'react-icons/io5';
import { FaSpinner, FaUsers } from 'react-icons/fa';
import { MdBathtub } from 'react-icons/md';
import { Carousel } from 'react-responsive-carousel';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const RoomCard = ({ room, handler, isLoading, button }) => {
	// Parse the JSON string in the images property
	const imagesObject = JSON.parse(room?.images) ?? {};

	// Extract image URLs from the parsed object
	const imageUrls = Object.values(imagesObject);

	const perksArray = room?.perks.split(',');

	const [showVideo, setShowVideo] = useState(false);
	const [currentSlide, setCurrentSlide] = useState(0);

	const handleSlideChange = (index) => {
		setCurrentSlide(index);

		// Check if the current slide is the last one
		if (index === imageUrls.length && room.video_link !== null) {
			setShowVideo(true);
		} else {
			setShowVideo(false);
		}
	};

	return (
		<div className='my-8  mx-auto border border-gray-300 shadow-lg'>
			<div className='flex flex-col justify-around md:flex-row md:items-center'>
				<div className='md:w-1/2 w-full'>
					<Carousel
						showThumbs={false}
						infiniteLoop={true}
						autoPlay={showVideo ? false : true}
						interval={4000}
						showStatus={false}
						onChange={(index) => handleSlideChange(index)}
						renderArrowPrev={(onClickHandler, hasPrev, label) =>
							hasPrev && (
								<button
									className='absolute left-1 top-1/2 transform -translate-y-1/2    cursor-pointer z-10 bg-black bg-opacity-70 rounded-full flex items-center justify-center p-1'
									onClick={onClickHandler}
									title={label}
								>
									<IoIosArrowBack
										size={16}
										color='white'
									/>
								</button>
							)
						}
						renderArrowNext={(onClickHandler, hasNext, label) =>
							hasNext && (
								<button
									className='absolute right-1 top-1/2 transform -translate-y-1/2    cursor-pointer z-10 bg-black bg-opacity-70 rounded-full flex items-center justify-center p-1'
									onClick={onClickHandler}
									title={label}
								>
									<IoIosArrowForward
										size={16}
										color='white'
									/>
								</button>
							)
						}
					>
						{imageUrls?.map((imageUrl, index) => {
							const image = `https://res.cloudinary.com/du5mxasbv/image/upload/${imageUrl}`;

							return (
								<div className='md:h-[340px] h-56'>
									<img
										key={index + 1}
										alt={`Room ${index + 1}`}
										className='object-cover object-center'
										src={`${image !== null ? image : 'https://dummyimage.com/400x400'}`}
									/>
								</div>
							);
						})}
						{room.video_link === null || !showVideo || !currentSlide !== imageUrls.length ? null : (
							<div className={`h-full w-full flex items-center justify-center ${room.video_link === null ? 'hidden' : ''}`}>
								<iframe
									className='min-h-full min-w-full absolute top-0'
									src={`https://www.youtube.com/embed/${room.video_link}`}
									title={room.apartment_name}
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
									allowFullScreen
								></iframe>{' '}
							</div>
						)}
					</Carousel>
				</div>
				<div className='lg:w-1/2 w-full lg:pl-10 lg:py-6 my-6 lg:mt-0 px-4'>
					<h2 className='text-sm md:text-base title-font text-gray-500 tracking-widest'>
						{room.locality}, {room.city}
					</h2>
					<h1 className='text-gray-900 text-xl md:text-3xl title-font font-medium mb-1'> {room.apartment_name}</h1>
					<div className='flex flex-wrap gap-2 pb-1 my-2 md:my-4'>
						{perksArray.map((perk, index) => (
							<span
								key={perk}
								className='bg-[#5F8670] text-white px-2 md:px-3 py-[2px] rounded-full text-xs md:text-sm flex items-center text-center'
							>
								{perk}
							</span>
						))}
					</div>
					<div className='grid gap-2 grid-cols-2 my-4 rounded-md'>
						<div className='flex gap-2 justify-center bg-gray-100 py-1 rounded-full  items-center  md:text-base'>
							<IoBed
								size={20}
								color='red'
							/>
							<span className='text-gray-500 text-sm text-center font-medium'>{room.accomotation_type}</span>
						</div>
						<div className='flex gap-2 justify-center bg-gray-100 py-1 rounded-full  items-center  md:text-base'>
							<GiSofa
								size={20}
								color='red'
							/>
							<span className='text-gray-500 text-sm text-center font-medium'>{room.category}</span>
						</div>
						<div className='flex gap-2 justify-center bg-gray-100 py-1 rounded-full  items-center  md:text-base'>
							<FaUsers
								size={20}
								color='red'
							/>
							<span className='text-gray-500 text-sm text-center font-medium'>{room.tenant}</span>
						</div>
						<div className='flex gap-2 justify-center bg-gray-100 py-1 rounded-full  items-center  md:text-base'>
							<MdBathtub
								size={20}
								color='red'
							/>
							<span className='text-gray-500 text-sm text-center font-medium'>{room.washroom_status}</span>
						</div>
					</div>
					<div className='flex gap-2 justify-between mt-8 md:mt-10'>
						<div>
							<span className='font-semibold text-xl md:text-2xl text-gray-900'>&#8377;{room.rent_price}</span>
							<span className='text-sm text-gray-800'>/{room.rate}</span>
						</div>
						{button && (
							<button
								className={`flex justify-center text-white bg-red-500 border-0 py-1 md:py-2 items-center gap-2 px-4 text-sm focus:outline-none hover:bg-red-600 rounded-full md:text-lg whitespace-nowrap transform transition-transform  active:scale-90`}
								onClick={() => handler(room)}
								disabled={isLoading}
							>
								{button} {isLoading && <FaSpinner className='animate-spin text-white' />}
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default RoomCard;

import React from 'react';
import Wrapper from '../../components/Wrapper';

const Mess = () => {
	return (
		<div className='my-24'>
			<Wrapper>
				<h2 className='text-center my-4 uppercase font-semibold text-gray-800'>All mess near you</h2>
				<div className='mx-4'>
					<div className='rounded-3xl border border-gray-300 overflow-hidden'>
						<div className='w-full'>
							<img
								src='https://images.pexels.com/photos/1640777/pexels-photo-1640777.jpeg'
								alt='food'
								className='object-contain'
							/>
						</div>
						<div className='p-4'>
							<h1 className='text-xl font-semibold'>Halfy Food</h1>
							<span className='text-sm text-gray-700'>35-40 min | 4.5km</span>
							<div></div>
						</div>
					</div>
				</div>
			</Wrapper>
		</div>
	);
};

export default Mess;

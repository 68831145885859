import React, { useEffect, useState, useRef } from 'react';
import Hammer from 'hammerjs';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPgsByCityname } from '../redux-store/slice/pgSlice';
import RoomCard from '../components/RoomDetails/RoomCard';

const Swipe = () => {
	const { cityname } = useParams();

	const dispatch = useDispatch();
	const { pgs, isLoading, isError } = useSelector((state) => state.fetchPgs);

	useEffect(() => {
		dispatch(fetchPgsByCityname(cityname));
	}, [cityname, dispatch]);
	const [cards, setCards] = useState([
		{ id: 1, imageUrl: 'https://placeimg.com/600/300/people', title: 'Demo card 1', description: 'This is a demo for Tinder like swipe cards' },
		{ id: 2, imageUrl: 'https://placeimg.com/600/300/animals', title: 'Demo card 2', description: 'This is a demo for Tinder like swipe cards' },
		{ id: 3, imageUrl: 'https://placeimg.com/600/300/nature', title: 'Demo card 3', description: 'This is a demo for Tinder like swipe cards' },
		{ id: 4, imageUrl: 'https://placeimg.com/600/300/tech', title: 'Demo card 4', description: 'This is a demo for Tinder like swipe cards' },
		{ id: 5, imageUrl: 'https://placeimg.com/600/300/arch', title: 'Demo card 5', description: 'This is a demo for Tinder like swipe cards' },
	]);

	const tinderContainerRef = useRef(null);

	useEffect(() => {
		const tinderContainer = tinderContainerRef.current;
		const allCards = document.querySelectorAll('.tinder--card');
		const nope = document.getElementById('nope');
		const love = document.getElementById('love');

		const initCards = () => {
			const newCards = document.querySelectorAll('.tinder--card:not(.removed)');

			newCards.forEach((card, index) => {
				card.style.zIndex = allCards.length - index;
				card.style.transform = `scale(${(20 - index) / 20}) translateY(-${30 * index}px)`;
				card.style.opacity = (10 - index) / 10;
			});

			tinderContainer.classList.add('loaded');
		};

		initCards();

		allCards.forEach((el) => {
			const hammertime = new Hammer(el);

			hammertime.on('pan', (event) => {
				el.classList.add('moving');

				if (event.deltaX === 0) return;
				if (event.center.x === 0 && event.center.y === 0) return;

				tinderContainer.classList.toggle('tinder_love', event.deltaX > 0);
				tinderContainer.classList.toggle('tinder_nope', event.deltaX < 0);

				const xMulti = event.deltaX * 0.03;
				const yMulti = event.deltaY / 80;
				const rotate = xMulti * yMulti;

				event.target.style.transform = `translate(${event.deltaX}px, ${event.deltaY}px) rotate(${rotate}deg)`;
			});

			hammertime.on('panend', (event) => {
				el.classList.remove('moving');
				tinderContainer.classList.remove('tinder_love');
				tinderContainer.classList.remove('tinder_nope');

				const moveOutWidth = document.body.clientWidth;
				const keep = Math.abs(event.deltaX) < 80 || Math.abs(event.velocityX) < 0.5;

				event.target.classList.toggle('removed', !keep);

				if (keep) {
					event.target.style.transform = '';
				} else {
					const endX = Math.max(Math.abs(event.velocityX) * moveOutWidth, moveOutWidth);
					const toX = event.deltaX > 0 ? endX : -endX;
					const endY = Math.abs(event.velocityY) * moveOutWidth;
					const toY = event.deltaY > 0 ? endY : -endY;
					const xMulti = event.deltaX * 0.03;
					const yMulti = event.deltaY / 80;
					const rotate = xMulti * yMulti;

					event.target.style.transform = `translate(${toX}px, ${toY + event.deltaY}px) rotate(${rotate}deg)`;
					initCards();
				}
			});
		});

		const createButtonListener = (love) => {
			return (event) => {
				const cards = document.querySelectorAll('.tinder--card:not(.removed)');
				const moveOutWidth = document.body.clientWidth * 1.5;

				if (!cards.length) return false;

				const card = cards[0];

				card.classList.add('removed');

				if (love) {
					card.style.transform = `translate(${moveOutWidth}px, -100px) rotate(-30deg)`;
				} else {
					card.style.transform = `translate(-${moveOutWidth}px, -100px) rotate(30deg)`;
				}

				initCards();

				event.preventDefault();
			};
		};

		const nopeListener = createButtonListener(false);
		const loveListener = createButtonListener(true);

		nope.addEventListener('click', nopeListener);
		love.addEventListener('click', loveListener);
	}, []);

	return (
		<div
			ref={tinderContainerRef}
			className='tinder my-20'
		>
			<div className='tinder--status '>
				<i className='fa fa-remove '></i>
				<i className='fa fa-heart '></i>
			</div>

			<div className='tinder--cards '>
				{pgs.map((room) => (
					<div
						key={room._id}
						className='tinder--card'
					>
						<RoomCard
							room={room}
							cityname={cityname}
							// handler={handleAddToWishlist}
							// isLoading={isAdding}
							button='Shortlist'
						/>
					</div>
				))}
			</div>

			<div className='tinder--buttons '>
				<button id='nope'>
					<i className='fa fa-remove '></i>
				</button>
				<button id='love'>
					<i className='fa fa-heart '></i>
				</button>
			</div>
		</div>
	);
};

export default Swipe;

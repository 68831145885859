import axios from 'axios';
import { domain } from '../../utils/domain';

const salesToken = localStorage.getItem('salesToken');

const headers = {
	accept: 'application/json',
	Authorization: 'Bearer ' + salesToken,
};

export const RegisterAccomodation = async (postData) => {
	if (salesToken) {
		try {
			const response = await axios.post(`${domain}/register_accommodation/`, postData, { headers });
			return response.data;
		} catch (error) {
			console.error('Failed to Post accomodation  :' + error.message);
			throw error;
		}
	}
};

export const GetRoomId = async (accid) => {
	if (salesToken) {
		try {
			const response = await axios.get(`${domain}/get_roomid/?accid=${accid}`, { headers });
			return response.data;
		} catch (error) {
			console.error('Failed to get roomid  :' + error.message);
			throw error;
		}
	}
};
export const RegisterRoom = async (postData) => {
	if (salesToken) {
		try {
			const response = await axios.post(`${domain}/register_room/`, postData, { headers });
			return response.data;
		} catch (error) {
			console.error('Failed to Post room  :' + error.message);
			throw error;
		}
	}
};

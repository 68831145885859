import React, { useEffect } from 'react';
import Accomodation from '../../components/OwnerSide/Accomodation';
import Wrapper from '../../components/Wrapper';
import { PiSmileySadThin } from 'react-icons/pi';
import { SkeletonLoader } from '../../components/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOwnerRoomList } from '../../redux-store/slice/ownerSlice';

const AccomodationsList = () => {
	const dispatch = useDispatch();
	const ownerRoomList = useSelector((state) => state.owner.ownerRoomList);
	const isLoading = useSelector((state) => state.owner.isLoading);
	const isError = useSelector((state) => state.owner.isError);

	useEffect(() => {
		dispatch(fetchOwnerRoomList());
	}, []);

	return (
		<div className='my-24'>
			<Wrapper>
				<h1 className='text-center font-semibold text-gray-800 text-xl'>Your Accomodations List</h1>
				{isError ? (
					<div className='text-center mt-6 text-red-500'>Network Error, Please try again</div>
				) : (
					<div className='flex flex-col'>
						{isLoading && (
							<>
								<SkeletonLoader />
								<SkeletonLoader />
								<SkeletonLoader />
							</>
						)}
						{ownerRoomList?.length > 0
							? ownerRoomList?.map((room) => (
									<Accomodation
										key={room.roomid}
										room={room}
									/>
							  ))
							: !isLoading && (
									<div
										className={`w-full flex flex-col items-center justify-center mt-24 px-12 gap-8 ${
											isLoading ? 'hidden' : ''
										}`}
									>
										<PiSmileySadThin
											size={68}
											color='gray'
										/>
										<div className='flex flex-col items-center gap-1'>
											<p className='text-gray-500 font-medium text-center'> You dont list any accomodation</p>{' '}
										</div>
									</div>
							  )}
					</div>
				)}
			</Wrapper>
		</div>
	);
};

export default AccomodationsList;

import axios from 'axios';
import { domain } from '../../utils/domain';

const headers = {
	accept: 'application/json',
};

export const RegisterUser = async (userdata) => {
	try {
		const response = await axios.post(`${domain}/register_user`, userdata, headers);

		return response.data;
	} catch (error) {
		console.error('Error in registering user : ' + error.message);
		throw error;
	}
};

export const SalesLoginOnboard = async (username, password) => {
	try {
		const response = await axios.post(`${domain}/sales_login/?sales_id=${username}&password=${password}`, headers);
		return response.data;
	} catch (error) {
		console.error('Error in login sales : ' + error.message);
		throw error;
	}
};

import moment from 'moment';
import React from 'react';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';

const AgentPaymentHistoryCard = ({ payment }) => {
	return (
		<div className='w-full py-4 px-1 border mb-8 shadow-md rounded'>
			<div className='flex flex-col gap-4'>
				<div className='rounded p-3 flex gap-2 flex-col'>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<span className='font-medium text-sm text-gray-600'> Order Id - </span>
						<span className='text-sm font-semibold text-gray-900'>{payment.orderno}</span>
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<span className='font-medium text-gray-600 text-sm'>Service Area - </span>
						<span className='text-sm font-semibold text-gray-900'>{payment.service_area}</span>
					</div>
					{payment.bonus !== null && (
						<div className='flex items-center gap-3  p-1 rounded pl-3'>
							<span className='font-medium text-gray-600 text-sm'>Bonus - </span>
							<span className='text-sm font-semibold text-gray-900'>{payment.bonus}</span>
						</div>
					)}
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<span className='font-medium text-gray-600 text-sm'>Rating - </span>
						<span className='text-sm font-semibold text-gray-900'>{payment.agent_rating}</span>
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<span className='font-medium text-gray-600 text-sm'>Visited Date - </span>
						<span className='text-sm font-semibold text-gray-900'>{moment(payment.vist_date, 'YYYY-MM-DD').format('D MMM')}</span>
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<span className='font-medium text-gray-600 text-sm'>Start Time - </span>
						<span className='text-sm font-semibold text-gray-900'>{moment(payment.start_time, 'HH:mm:ss').format('h A')}</span>
					</div>
					<div className='flex items-center gap-3  p-1 rounded pl-3'>
						<span className='font-medium text-gray-600 text-sm'>End Time - </span>
						<span className='text-sm font-semibold text-gray-900'>{moment(payment.end_time, 'HH:mm:ss').format('h A')}</span>
					</div>
				</div>
				<div className='w-full'>
					{payment.payment_status === 'pending' ? (
						<button
							className='bg-yellow-500 hover:bg-yellow-600 px-4 py-1 rounded-full text-white uppercase text-sm
         flex items-center justify-center gap-2 w-full'
						>
							<span>Status : {payment.payment_status !== null ? payment.payment_status : 'Not defined'}</span>
							<FaExclamationCircle />
						</button>
					) : payment.payment_status === 'success' ? (
						<button
							className='bg-green-500 hover:bg-green-600 px-4 py-1 rounded-full text-white uppercase text-sm
         flex items-center justify-center gap-2 w-full'
						>
							<span>Status : {payment.payment_status}</span> <FaCheckCircle />
						</button>
					) : (
						<button
							className='bg-red-500 hover:bg-red-600 px-4 py-1 rounded-full text-white uppercase text-sm
 flex items-center justify-center gap-2 w-full'
						>
							<span>Payment : Not Defined</span>
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default AgentPaymentHistoryCard;

import { configureStore } from '@reduxjs/toolkit';
import agentReducer from './slice/agentSlice';
import wishlistSlice from './slice/wishlistSlice';
import pgSlice from './slice/pgSlice';
import onBoardSlice from './slice/onBoardSlice';
import ownerSlice from './slice/ownerSlice';

const store = configureStore({
	reducer: {
		agent: agentReducer,
		wishlist: wishlistSlice,
		fetchPgs: pgSlice,
		onboard: onBoardSlice,
		owner: ownerSlice,
	},
});

export default store;

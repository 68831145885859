import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AssignVisitSchedule, FetchAgentVisit } from '../../api/student/student';

export const fetchAgentsData = createAsyncThunk('agent/setAgents', async () => {
	const data = await FetchAgentVisit();
	return data;
});

export const assignVisitForAgent = createAsyncThunk('agent/assignVisitForAgent', async (postData) => {
	const data = await AssignVisitSchedule(postData);
	return data;
});

const agentSlice = createSlice({
	name: 'agent',
	initialState: {
		agents: [],
		isLoading: false,
		isError: false,
	},
	extraReducers: (builder) => {
		builder.addCase(fetchAgentsData.pending, (state) => {
			state.isLoading = true;
		})
			.addCase(fetchAgentsData.fulfilled, (state, action) => {
				state.isLoading = false;
				state.agents = action.payload;
			})
			.addCase(fetchAgentsData.rejected, (state) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(assignVisitForAgent.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(assignVisitForAgent.fulfilled, (state) => {
				// Handle the response as needed
				state.isLoading = false;
			})
			.addCase(assignVisitForAgent.rejected, (state) => {
				state.isLoading = false;
				state.isError = true;
			});
	},
});

export default agentSlice.reducer;

import React from 'react';
import { CiLogin } from 'react-icons/ci';
import { FaPenSquare } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Wrapper from '../Wrapper';

const OnBoard = () => {
	return (
		<Wrapper>
			<div className='mt-8 md:mt-16 mx-3'>
				<div className='flex flex-col text-center w-full mb-4'>
					<h1 className='sm:text-2xl text-xl font-semibold title-font text-gray-700'>On Board Your PG Search</h1>
				</div>
				<div className='flex w-full justify-center gap-4'>
					<Link
						to='/Register'
						className='flex gap-2 items-center bg-red-500 hover:bg-red-600 px-3 py-2'
					>
						<FaPenSquare
							size={24}
							color='white'
						/>
						<span className='text-white uppercase'>Register</span>
					</Link>
					<Link
						to='/userLogin'
						className='flex gap-2 items-center border  border-black hover:bg-gray-100 px-3 py-2'
					>
						<CiLogin size={24} />
						<span className='uppercase'>Login</span>
					</Link>
				</div>
			</div>
		</Wrapper>
	);
};

export default OnBoard;

import React, { useEffect } from 'react';
import { loadOTPlessScript } from '../../utils/auth';
import Wrapper from '../../components/Wrapper';
import { LoginAgentOtpLess } from '../../api/auth/agentLogin';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const AgentLogin = () => {
	const navigate = useNavigate();

	const token = localStorage.getItem('token');
	const ownerToken = localStorage.getItem('ownerToken');
	const agentToken = localStorage.getItem('agentToken');

	useEffect(() => {
		if (!token && !ownerToken && !agentToken) {
			loadOTPlessScript();
			window.otpless = (otplessUser) => {
				const PostData = async () => {
					const userData = {
						contact: otplessUser.mobile.number,
						token: otplessUser.token,
						user_type: 'agent',
					};
					try {
						const data = await LoginAgentOtpLess(userData);
						if (data) {
							toast.success('Login Successfully');
							setTimeout(() => {
								navigate('/agentTodayOrder');
								window.location.reload();
							}, 2000);
						}
					} catch (error) {
						console.error(error);
					}
				};
				PostData();
			};
		} else {
			navigate('/');
		}
	}, []);

	return (
		<div className='otpless-container my-20'>
			<Wrapper>
				<div className='otpless-modal'>
					<div id='otpless-login-page'></div>
				</div>
			</Wrapper>
		</div>
	);
};

export default AgentLogin;

import axios from 'axios';

import { domain } from '../../utils/domain';

const token = localStorage.getItem('token');
export const GetWishlistedRooms = async () => {
	if (token) {
		try {
			const response = await axios.get(`${domain}/wishlist_pg`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
			});
			return response.data;
		} catch (error) {
			console.error(`Failed to get wishlisted room  : ${error.message}`);
			throw error;
		}
	}
};
export const PostWishlistedRoom = async (endpoint, postData) => {
	if (token) {
		try {
			const response = await axios.post(`${domain}/${endpoint}`, postData, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
			});
			return response.data;
		} catch (error) {
			console.error(`Failed to post wishlisted room to ${endpoint} : ${error.message}`);
		}
	}
};

export const RemoveWishlistedRoom = async (endpoint, entery_id) => {
	if (token) {
		try {
			const response = await axios.delete(`${domain}/${endpoint}/?id=${entery_id}`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
			});
			return response.data;
		} catch (error) {
			console.error(`Failed to delete wishlisted room to ${endpoint} : ${error.message}`);
		}
	}
};

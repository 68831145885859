import React from 'react';

const Loader = () => {
	return (
		<div className='flex items-center justify-center mt-4'>
			<div className='animate-spin rounded-full h-16 w-16 border-t-4 border-red-500'></div>
		</div>
	);
};

export default Loader;

export const SkeletonLoader = () => {
	return (
		<div className='max-w-sm mx-3 p-4 md:flex justify-between md:gap-6  rounded shadow animate-pulse md:p-6 md:min-w-full'>
			<div className='flex items-center justify-center md:h-[300px] h-56 md:min-w-[500px] mb-4 bg-gray-300 rounded dark:bg-gray-500'>
				<svg
					className='w-10 h-10 text-gray-200 dark:text-gray-600'
					aria-hidden='true'
					xmlns='http://www.w3.org/2000/svg'
					fill='currentColor'
					viewBox='0 0 16 20'
				>
					<path d='M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z' />
					<path d='M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z' />
				</svg>
			</div>
			<div className='w-full'>
				<div className='h-2.5 md:h-8 md:w-full  bg-gray-200 rounded-full dark:bg-gray-500 w-48 mb-4'></div>
				<div className='h-2 md:h-6 md:w-1/2 bg-gray-200 rounded-full dark:bg-gray-500 mb-2.5'></div>
				<div className='h-2 md:h-6 md:w-1/2  bg-gray-200 rounded-full dark:bg-gray-500 mb-2.5'></div>
				<div className='h-2 md:h-6 md:w-1/2  bg-gray-200 rounded-full dark:bg-gray-500'></div>
				<div className='flex items-center mt-4 md:mt-16'>
					<div>
						<div className='h-2.5 md:h-4  bg-gray-200 rounded-full dark:bg-gray-500 w-32 mb-2'></div>
						<div className='w-48 h-2 md:h-4 bg-gray-200 rounded-full dark:bg-gray-500'></div>
					</div>
				</div>
				<span className='sr-only'>Loading...</span>
			</div>
		</div>
	);
};

export const SkeletonLoader2 = () => {
	return (
		<div className='flex flex-col  gap-3 mt-4 animate-pulse'>
			<div className='w-full h-8 rounded-md bg-gray-100 dark:bg-gray-300'></div>
			<div className='w-full h-8 rounded-md bg-gray-100 dark:bg-gray-300'></div>
			<div className='w-full h-8 rounded-md bg-gray-100 dark:bg-gray-300'></div>
			<div className='w-full h-8 rounded-md bg-gray-100 dark:bg-gray-300'></div>
			<div className='w-full h-8 rounded-md bg-gray-100 dark:bg-gray-300'></div>
			<div className='w-full h-8 rounded-md bg-gray-100 dark:bg-gray-300'></div>
		</div>
	);
};

import React, { useState } from 'react';
import Wrapper from '../../components/Wrapper';
import { SalesLoginOnboard } from '../../api/auth/register';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const SalesLogin = () => {
	const navigate = useNavigate();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);

	const handleLogin = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		setIsError(false);
		setUsername('');
		setPassword('');
		try {
			const res = await SalesLoginOnboard(username, password);

			localStorage.setItem('salesToken', res.access_token);
			toast.success('Logged in successfully');
			setIsLoading(false);
			setTimeout(() => {
				navigate('/addAccomodation');
			}, 2000);
		} catch (error) {
			console.error(error);
			setIsError(true);
			toast.error('Invalid Credentials');
			setIsLoading(false);
		}
	};

	return (
		<div className='my-24 mx-4'>
			<Wrapper>
				<h1 className='font-semibold text-2xl text-gray-700 text-center'>Sales Login</h1>
				<form
					className='flex flex-col gap-8 mt-8 mx-auto w-full'
					onSubmit={handleLogin}
				>
					<div className='flex flex-col gap-3'>
						<label
							htmlFor='username'
							className='text-sm text-gray-700 font-semibold'
						>
							Username
						</label>
						<input
							type='text'
							name='username'
							id='username'
							placeholder='Enter username here'
							className='border border-gray-300 shadow-sm px-2 py-1 rounded text-sm'
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							required
						/>
					</div>
					<div className='flex flex-col gap-3'>
						<label
							htmlFor='username'
							className='text-sm text-gray-700 font-semibold'
						>
							Password
						</label>
						<input
							type='password'
							name='password'
							id='password'
							placeholder='Enter password here'
							className='border border-gray-300 shadow-sm px-2 py-1 rounded text-sm'
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							required
						/>
					</div>
					{isLoading ? (
						<button
							type='submit'
							className='bg-red-500 cursor-not-allowed text-white py-0.5 rounded-full'
						>
							Logging in ...
						</button>
					) : (
						<button
							type='submit'
							className='bg-red-500 hover:bg-red-600 text-white py-1 text-sm rounded-full'
						>
							Login
						</button>
					)}
				</form>
			</Wrapper>
		</div>
	);
};

export default SalesLogin;

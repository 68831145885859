import React from 'react';
import Wrapper from '../Wrapper';
import { MdLocationCity, MdMeetingRoom, MdVerifiedUser, MdSentimentVerySatisfied } from 'react-icons/md';

const StatsSection = () => {
	return (
		<div className='w-full'>
			<Wrapper>
				<div className='flex justify-around'>
					<div className='flex flex-col items-center'>
						<MdLocationCity
							color='red'
							size={28}
						/>
						<p className='font-semibold'>10+</p>
						<p className='text-gray-700'>Cities</p>
					</div>
					<div className='flex flex-col items-center'>
						<MdMeetingRoom
							color='red'
							size={28}
						/>
						<p className='font-semibold'>500+</p>
						<p className=''>Pg's</p>
					</div>
					<div className='flex flex-col items-center'>
						<MdVerifiedUser
							color='red'
							size={28}
						/>
						<p className='font-semibold'>800+</p>
						<p className=''>Reviews</p>
					</div>
					<div className='flex flex-col items-center'>
						<MdSentimentVerySatisfied
							color='red'
							size={28}
						/>
						<p className='font-semibold'>1000+</p>
						<p className=''>Customers</p>
					</div>
				</div>
			</Wrapper>
		</div>
	);
};

export default StatsSection;

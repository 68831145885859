import axios from 'axios';
import { domain } from '../../utils/domain';

const token = localStorage.getItem('token');

const headers = {
	accept: 'application/json',
	Authorization: 'Bearer ' + token,
};

export const FetchStudentBookings = async (orderno) => {
	if (token) {
		try {
			const response = await axios.get(`${domain}/vist_detail/?orderno=${orderno}`, {
				headers,
			});
			return response.data;
		} catch (error) {
			console.error('Failed to fetch student bookings :' + error.message);
			throw error;
		}
	}
};

export const AssignVisitSchedule = async (postData) => {
	if (token) {
		try {
			const response = await axios.post(`${domain}/assign-rm`, postData, {
				headers,
			});
			return response.data;
		} catch (error) {
			console.error('Failed to post student visit schedule :' + error.message);
			throw error;
		}
	}
};

export const FetchAgentVisit = async () => {
	if (token) {
		try {
			const response = await axios.get(`${domain}/agents_vist`, {
				headers,
			});
			return response.data;
		} catch (error) {
			console.error('Failed to fetch agent visit :' + error.message);
			throw error;
		}
	}
};

export const CancelVisitSchedule = async (id) => {
	if (token) {
		try {
			const response = await axios.put(`${domain}/cancel_schedule/?id=${id}`, null, {
				headers,
			});
			return response.data;
		} catch (error) {
			console.error('Failed to cancel visit schedule :' + error.message);
			throw error;
		}
	}
};

import React, { useEffect, useState } from 'react';
import Wrapper from '../../components/Wrapper';
import AgentTodayOrderCard from '../../components/AgentSide/AgentTodayOrderCard';
import { PiSmileySadThin } from 'react-icons/pi';
import Loader from '../../components/Loader/Loader';
import { FetchTodayOrders } from '../../api/agent/agent';

const AgentTodayOrder = () => {
	const [todayOrders, setTodayOrders] = useState([]);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsError(false);
		setIsLoading(true);
		const fetchData = async () => {
			try {
				const data = await FetchTodayOrders();
				setTodayOrders(data);
				setIsLoading(false);
			} catch (error) {
				console.error(error);
				setIsError(true);
				setIsLoading(false);
			}
		};
		fetchData();
	}, []);

	return (
		<div className='my-24 mx-4'>
			<Wrapper>
				<h1 className='text-center font-semibold text-gray-800 text-xl mb-8'>Your Today Orders</h1>
				{isError ? (
					<div className='text-center mt-6 text-red-500'>Network Error, Please try again</div>
				) : (
					<div className='flex flex-col'>
						{isLoading && (
							<div className='flex justify-center'>
								<Loader />{' '}
							</div>
						)}
						{todayOrders?.length > 0 ? (
							todayOrders?.map((order) => (
								<AgentTodayOrderCard
									order={order}
									key={order.orderno}
								/>
							))
						) : (
							<div className={`w-full flex flex-col items-center justify-center mt-24 px-12 gap-8 ${isLoading ? 'hidden' : ''}`}>
								<PiSmileySadThin
									size={68}
									color='gray'
								/>
								<div className='flex flex-col items-center gap-1'>
									<p className='text-gray-500 font-medium'> No Today orders</p>{' '}
								</div>
							</div>
						)}
					</div>
				)}
			</Wrapper>
		</div>
	);
};

export default AgentTodayOrder;

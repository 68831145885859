import { useEffect, useState } from 'react';
import './App.css';

//IMPORTING LIBRARIES
import { HashRouter, Route, Routes } from 'react-router-dom';

//IMPORTING COMPONENTS
import Footer from './components/Navs/Footer';
import Header from './components/Navs/Header';

//IMPORTING ROUTES
import MainLandingPage from './pages/MainLandingPage';
import Reviews from './pages/Reviews';
import RoomList from './pages/RoomList';

//OWNER SIDE ROUTES
import AccomodationsList from './pages/owner/AccomodationsList';
import OwnerBookingSchedules from './pages/owner/OwnerBookingSchedules';
import OwnerPaymentHistory from './pages/owner/OwnerPaymentHistory';
import ScratchCards from './pages/owner/ScratchCards';

//STUDENT SIDE ROUTES
import Cashback from './pages/student/Cashback';
import StudentBookings from './pages/student/StudentBookings';
import WishlistedRoomList from './pages/student/WishlistedRoomList';
import PgReview from './pages/student/PgReview';

//AGENT SIDE ROUTES
import AgentOrderDetails from './pages/agent/AgentOrderDetails';
import AgentPaymentHistory from './pages/agent/AgentPaymentHistory';
import AgentTodayOrder from './pages/agent/AgentTodayOrder';

//AUTH ROUTES
import AgentLogin from './pages/auth/AgentLogin';
import OwnerLogin from './pages/auth/OwnerLogin';
import Register from './pages/auth/Register';
import SalesLogin from './pages/auth/SalesLogin';
import UserLogin from './pages/auth/UserLogin';

//PRIVATE ROUTES FOR ONLY AUTHENTICATED USERS
import AgentPrivateRoute from './utils/private-routes/AgentPrivateRoute';
import OwnerPrivateRoute from './utils/private-routes/OwnerPrivateRoute';
import SalesPrivateRoute from './utils/private-routes/SalesPrivateRoute';
import StudentPrivateRoute from './utils/private-routes/StudentPrivateRoute';

//ONBOARD ROUTES
import AddAccomodation from './pages/onBoard/AddAccomodation';
import AddRoom from './pages/onBoard/AddRoom';
import Success from './pages/onBoard/Success';

//FOR NOTIFICATIONS
import { onMessageListener, requestPermissionForNotification } from './utils/firebase/firebase';

import { Toaster } from 'react-hot-toast';
import Mess from './pages/student/Mess';
import NewPgListing from './pages/student/NewPgListing';
import TermsAndConditions from './pages/student/TermsAndConditions';

import ReactGA from 'react-ga4';
import NotFound from './pages/NotFound';
import PgDetails from './pages/student/PgDetails';
import Swipe from './pages/Swipe';

const measurementId = 'G-KRVPC4E158';
ReactGA.initialize(measurementId);

ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Pgvala' });

function App() {
	const [deviceToken, setDeviceToken] = useState('');

	useEffect(() => {
		const requestPermissionAndSubscribe = async () => {
			const token = await requestPermissionForNotification();
			setDeviceToken(token);
			const unsubscribe = onMessageListener();
			unsubscribe
				?.then((payload) => {
					console.log(payload);
				})
				.catch((err) => console.log(err));
		};
		requestPermissionAndSubscribe();
	}, []);

	return (
		<HashRouter>
			<Header />
			<Footer />
			<Toaster />
			<Routes>
				<Route
					path='/'
					element={<MainLandingPage deviceToken={deviceToken} />}
				/>
				<Route
					path='/roomlist/:cityname'
					element={<RoomList />}
				/>
				<Route
					path='/roomlist/swipe/:cityname'
					element={<Swipe />}
				/>
				<Route
					path='/reviews'
					element={<Reviews />}
				/>
				<Route
					path='*'
					element={<NotFound />}
				/>

				{/* ONBOARD ROUTES */}
				<Route
					path='/addAccomodation'
					element={<SalesPrivateRoute Component={AddAccomodation} />}
				/>
				<Route
					path='/success/:roomid'
					element={<SalesPrivateRoute Component={Success} />}
				/>
				<Route
					path='/addRoom'
					element={<SalesPrivateRoute Component={AddRoom} />}
				/>

				{/* OWNER ROUTES */}
				<Route
					path='/accomodationList'
					element={<OwnerPrivateRoute Component={AccomodationsList} />}
				/>
				<Route
					path='/ownerBookingSchedules'
					element={<OwnerPrivateRoute Component={OwnerBookingSchedules} />}
				/>
				<Route
					path='/ownerPaymentHistory'
					element={<OwnerPrivateRoute Component={OwnerPaymentHistory} />}
				/>
				<Route
					path='/scratchCards'
					element={<OwnerPrivateRoute Component={ScratchCards} />}
				/>

				{/* USER ROUTES */}
				<Route
					path='/wishlist'
					element={<StudentPrivateRoute Component={WishlistedRoomList} />}
				/>
				<Route
					path='/studentBookings/:orderno'
					element={<StudentPrivateRoute Component={StudentBookings} />}
				/>
				<Route
					path='/cashback'
					element={<Cashback />}
				/>
				<Route
					path='/mess'
					element={<Mess />}
				/>
				<Route
					path='/registerOwner'
					element={<NewPgListing />}
				/>
				<Route
					path='/termsAndConditions'
					element={<TermsAndConditions />}
				/>
				<Route
					path='/pgDetails/:roomid'
					element={<PgDetails />}
				/>
				<Route
					path='/pgReview'
					element={<PgReview />}
				/>

				{/* AGENT ROUTES */}
				<Route
					path='/agentTodayOrder'
					element={<AgentPrivateRoute Component={AgentTodayOrder} />}
				/>
				<Route
					path='/agentOrderDetails/:orderid'
					element={<AgentPrivateRoute Component={AgentOrderDetails} />}
				/>
				<Route
					path='/agentPaymentHistory'
					element={<AgentPrivateRoute Component={AgentPaymentHistory} />}
				/>

				{/* AUTH ROUTES */}
				<Route
					path='/register'
					element={<Register />}
				/>
				<Route
					path='/salesLogin'
					element={<SalesLogin />}
				/>
				<Route
					path='/userLogin'
					element={<UserLogin />}
				/>
				<Route
					path='/agentLogin'
					element={<AgentLogin />}
				/>
				<Route
					path='/ownerLogin'
					element={<OwnerLogin />}
				/>
			</Routes>
		</HashRouter>
	);
}

export default App;

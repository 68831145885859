import React, { useEffect } from 'react';
import RoomCard from '../../components/RoomDetails/RoomCard';
import { fetchPgsByCityname } from '../../redux-store/slice/pgSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SkeletonLoader } from '../../components/Loader/Loader';
import Wrapper from '../../components/Wrapper';
import toast from 'react-hot-toast';

const PgDetails = () => {
	const { roomid } = useParams();

	const dispatch = useDispatch();

	const { pgs, isLoading, isError } = useSelector((state) => state.fetchPgs);

	useEffect(() => {
		dispatch(fetchPgsByCityname(roomid));
	}, [roomid]);

	if (isError) {
		return toast.error('Error in fetching details');
	}

	return (
		<div className='my-24 mx-4'>
			<Wrapper>{isLoading ? <SkeletonLoader /> : pgs?.map((room) => <RoomCard room={room} />)}</Wrapper>
		</div>
	);
};

export default PgDetails;
